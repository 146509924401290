import React from 'react';

const TwitterIcon = () => {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M21.2583 4.7879C21.2583 5.00477 21.2583 5.17827 21.2583 5.39513C21.2583 11.424 16.3953 18.3204 7.45662 18.3204C4.67776 18.3204 2.13046 17.5831 0 16.2819C0.370515 16.3253 0.741031 16.3686 1.15786 16.3686C3.42727 16.3686 5.51142 15.6313 7.17873 14.4168C5.04827 14.3734 3.24201 13.0722 2.63992 11.2506C2.96412 11.2939 3.24201 11.3373 3.56621 11.3373C3.98304 11.3373 4.44618 11.2506 4.8167 11.1638C2.59361 10.7301 0.926288 8.90839 0.926288 6.69634V6.65296C1.57469 6.99995 2.36204 7.17345 3.14938 7.21682C1.80626 6.39272 0.972603 5.00477 0.972603 3.44333C0.972603 2.57586 1.20417 1.79513 1.621 1.14453C4.02935 3.87706 7.64188 5.69875 11.6712 5.91561C11.5786 5.56863 11.5323 5.22164 11.5323 4.87465C11.5323 2.35899 13.7091 0.320435 16.3953 0.320435C17.7847 0.320435 19.0352 0.840916 19.9615 1.75176C21.0267 1.53489 22.092 1.14453 23.0183 0.624049C22.6477 1.70839 21.9067 2.57586 20.8878 3.13971C21.8604 3.05296 22.833 2.79272 23.6667 2.44574C23.0183 3.35658 22.1846 4.1373 21.2583 4.7879Z"
          fill="url(#g2)"
        />
      </g>
      <defs>
        <linearGradient id="g2" x1="2.21891" y1="2.76296" x2="26.2257" y2="4.18935" gradientUnits="userSpaceOnUse">
          <stop stop-color="#00B7F0" />
          <stop offset="1" stop-color="#4819BE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
