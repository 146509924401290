import React from 'react';
import { pepe_coin } from '../assets';
import Settings from './Settings/Settings';
import { useMediaQuery } from 'usehooks-ts';

const PepeDesktopFooter = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <>
      {!isMobile ? (
        <div className="hidden sm:block">
          <div className="absolute -bottom-[150px] left-0 pepefooter z-10 pointer-events-none">
            <img src={pepe_coin} className="w-[250px] h-auto" alt="" />
          </div>
          <Settings />
        </div>
      ) : null}
    </>
  );
};

export default PepeDesktopFooter;
