import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { cancel_icon_w_gradient, smartPepe } from '../../assets';
import { useAccount, useNetwork, useSigner, erc20ABI } from 'wagmi';
import { ethers, BigNumber } from 'ethers';
import { pepeLockingContractAddress, esPegTokenAddress, esPegStakingContractAddress } from '../../constants';
import { pepeStaking } from '../../utils/pepeStaking';
import { useSnackbar } from 'notistack';

const StakeModal = ({ open, isOpen }) => {
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);
  const { address } = useAccount();
  const [isComplete, setIsComplete] = useState(false);
  const [formattedInput, setFormattedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [pegBalance, setPegBalance] = useState(0);
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const { data: signer } = useSigner();
  const { enqueueSnackbar } = useSnackbar();
  const [hash, setHash] = useState('');
  const [isStaking, setIsStaking] = useState(false);

  const [isApproved, setIsApproved] = useState(false);

  const esPegStakingContract = useMemo(
    () => new ethers.Contract(esPegStakingContractAddress, pepeStaking, signer),
    [signer]
  );
  const esPegTokenContract = useMemo(() => new ethers.Contract(esPegTokenAddress, erc20ABI, signer), [signer]);

  const getUserBalance = async () => {
    try {
      const esPegBalance = await esPegTokenContract.balanceOf(address);
      console.log(esPegBalance);
      setPegBalance(ethers.utils.formatUnits(esPegBalance, 18).toLocaleString());
    } catch (error) {
      console.log('get balance', error);
    }
  };

  const getUserAllowance = async () => {
    try {
      const allowance = await esPegTokenContract.allowance(address, esPegStakingContractAddress);
      // console.log('available allowance:', allowance);
      setAllowance(allowance);
      if (!amount) {
        if (allowance.gt(ethers.utils.parseUnits('50', 18))) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
      } else {
        if (allowance.gte(ethers.utils.parseUnits(amount, 18))) {
          setIsApproved(true);
          return;
        }
        setIsApproved(false);
      }
    } catch (error) {
      console.log('get allowance:', error);
    }
  };

  useEffect(() => {
    getUserBalance();
    getUserAllowance();
  }, [signer]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();

      if (valid) {
        const formatted = value.replace(/,/g, '');

        setAmount(value.replace(/,/g, '') || '');
        setFormattedAmount(formatted);

        console.log(value.replace(/,/g, '') || '', formatted);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
      }
    },
    [floatRegexp]
  );

  const handleCustomAmountChange = async (percentage) => {
    const newAmount = (pegBalance * percentage) / 100;
    setAmount(newAmount);
    setFormattedAmount(newAmount);
  };

  // const getUserPegAllowance = useCallback(async () => {
  //   try {
  //     const allowance = await esPegTokenContract.allowance(address, esPegStakingContractAddress);
  //     console.log('available allowance:', allowance);
  //     setAllowance(allowance);
  //   } catch (error) {
  //     console.log('get allowance:', error);
  //   }
  // }, []);

  const handleApprove = async () => {
    console.log('logged');
    let parsedAmount;

    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }
    if (parsedAmount.lt(ethers.utils.parseUnits('1', 18))) {
      enqueueSnackbar('Invalid amount', {
        variant: 'error',
      });
      return;
    }
    if (parsedAmount.gt(allowance)) {
      try {
        await esPegTokenContract.approve(esPegStakingContractAddress, ethers.constants.MaxUint256);
        const allowance = await esPegTokenContract.allowance(address, esPegStakingContractAddress);
        setAllowance(allowance);
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Approval failed.', {
          variant: 'error',
        });
        return;
      }
    }
  };

  const handleStake = async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount.toString(), 18);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(BigNumber.from(0))) {
      let estimateGas;
      try {
        estimateGas = await esPegStakingContract.estimateGas.stake(parsedAmount);
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (e) {
        estimateGas = 3e6;
      }

      try {
        let tx = await esPegStakingContract.stake(parsedAmount, { gasLimit: estimateGas });
        const reciept = await tx.wait();
        console.log(reciept);
        setHash(reciept.transactionHash);
        setIsComplete(true);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Staking failed.', {
          variant: 'error',
        });
        return;
      }
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`modal-dialog transition-all transform ${
                  isComplete ? 'w-[739px] h-[600px]' : 'w-[628px] h-[344px]'
                } stake-modal flex max-[480px]:w-4/5 relative`}
              >
                <div className="flex flex-col justify-around w-full mx-auto">
                  <div className="flex items-end justify-end mr-4">
                    <button onClick={() => open(false)}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                    </button>
                  </div>
                  {isComplete ? (
                    <>
                      <div className='flex items-center justify-center text-4xl font-["Tourney"] font-extrabold gradient-text text-center'>
                        YEAH!
                      </div>
                      <div className="mb-40 text-center text-white barlow">
                        <p className="text-xl">You have successfully staked</p>
                        <p className='text-2xl font-["Tourney"] font-extrabold gradient-text'>{formattedInput} PEG</p>
                        <p className="text-xl">to your frenly dealer</p>
                        <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + hash}>
                          See tx
                        </a>
                      </div>
                      <img src={smartPepe} className="absolute bottom-0 left-0" />
                    </>
                  ) : (
                    <>
                      <div className='flex items-center justify-center text-2xl font-["Tourney"] font-extrabold gradient-text text-center'>
                        STAKING esPEG
                      </div>
                      <div className="flex flex-col items-center justify-between w-4/5 px-4 py-5 mx-auto text-white bg-deep-blue/70 stake-modal-shadow rounded-xl barlow sm:flex-row">
                        <p className="text-[#E0DDFF] text-sm">
                          <span className="mr-1">Balance:</span>
                          <span>{pegBalance} PEG</span>
                        </p>
                        <div className="flex items-center w-auto sm:w-1/2 justify-evenly">
                          {/* <p className='text-xl'>1,000,000.00</p> */}
                          <input className="dwf-funds-input-dark" onChange={handleChange} value={formattedInput} />
                          <button
                            onClick={() => handleCustomAmountChange(69)}
                            className="bg-white/10 opacity-80 rounded-md mr-2 p-1.5"
                          >
                            69%
                          </button>
                          <button
                            onClick={() => handleCustomAmountChange(100)}
                            className="bg-white/10 opacity-80 rounded-md p-1.5"
                          >
                            100%
                          </button>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <button
                          disabled={isApproved}
                          onClick={() => handleApprove()}
                          className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4"
                        >
                          {' '}
                          Approve{' '}
                        </button>
                        <button
                          onClick={() => handleStake()}
                          className="inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-xs inter mx-4"
                        >
                          {' '}
                          Stake{' '}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default StakeModal;
