import React from 'react';
import { pepe_coin } from '../assets';
import Settings from './Settings/Settings';
import { useMediaQuery } from 'usehooks-ts';

function PepeMobileFooter({ classes = '' }) {
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <>
      {isMobile ? (
        <div className={`flex sm:hidden ${classes}`}>
          <div className="w-2/5">
            <img alt="" src={pepe_coin} className="w-full h-auto pointer-events-none" />
          </div>
          <div className="flex-1 relative flex">
            <Settings classes="bottom-8 mr-0" />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PepeMobileFooter;
