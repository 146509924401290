import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import GradientBox from '../GradientBox/GradientBox';
import { useMediaQuery } from 'usehooks-ts';
import '../DepositWithdrawFunds/DepositWithdrawFunds.css';
import { StoreContext } from '../../utils/store';
import { CreditsContext } from '../../utils/creditsContext';
import { BigNumber, ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import { erc20ABI, useAccount, useSigner } from 'wagmi';
import { getSelectedTokenData } from '../../services/service';
import { abi } from '../../utils/pepebet-abi';

const MissingFunds = ({ isOpen, close }) => {
  const Store = useContext(StoreContext);
  let { depositWithdrawToken, depositWithdrawOperation, setDepositWithdrawOperation, setConnectModal } = Store;
  const { setYourCredits } = useContext(CreditsContext);
  const [isDepositing, setIsDepositing] = useState(false);
  const [amount, setAmount] = useState('0');
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const [formattedAmount, setFormattedAmount] = useState('0');
  const [userWalletBalance, setUserWalletBalance] = useState(BigNumber.from(0));
  const [formattedUserWalletBalance, setFormattedUserWalletBalance] = useState('0');
  const [userPepeBalance, setUserPepeBalance] = useState(BigNumber.from(0));
  const [formattedUserPepeBalance, setFormattedUserPepeBalance] = useState('0');
  const [isApproved, setIsApproved] = useState(false);
  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);
  const { enqueueSnackbar } = useSnackbar();
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const [doneWithdrawing, setDoneWithdrawing] = useState(false);

  const tokenData =
    depositWithdrawToken === undefined ? getSelectedTokenData('USDC') : getSelectedTokenData(depositWithdrawToken);

  const inAppContract = useMemo(() => {
    return new ethers.Contract(tokenData.contractAddress, abi, signer);
  }, [tokenData.contractAddress, signer]);

  const walletContract = useMemo(() => {
    return new ethers.Contract(tokenData.tokenAddres, erc20ABI, signer);
  }, [tokenData.tokenAddres, signer]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();

      if (valid) {
        const formatted = value.replace(/,/g, '');

        setAmount(value.replace(/,/g, '') || '');
        setFormattedAmount(formatted);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
      }
    },
    [floatRegexp]
  );

  const estimateGasUnits = async (entryFunction, amount) => {
    //type string && BigNumber resp.
    let estimatedCost;
    try {
      estimatedCost = await inAppContract.estimateGas[entryFunction](amount);
    } catch (e) {
      estimatedCost = BigNumber.from(0);
    }
    return estimatedCost;
  };

  const getUserWalletBalance = useCallback(async () => {
    try {
      const balance = await walletContract.balanceOf(address);
      setUserWalletBalance(balance);
      setFormattedUserWalletBalance(ethers.utils.formatUnits(balance, tokenData.formatUnits));
    } catch (e) {}
  }, [walletContract, address, tokenData.formatUnits]);

  const getUserPepeBalance = useCallback(async () => {
    try {
      const userBalance = await inAppContract.balances(address);
      setUserPepeBalance(userBalance);
      const formattedUserBalance = ethers.utils.formatUnits(userBalance, tokenData.formatUnits);
      setFormattedUserPepeBalance(formattedUserBalance);
      setYourCredits(ethers.utils.formatUnits(userBalance, tokenData.formatUnits));
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, inAppContract, depositWithdrawToken]);

  const getUserAllowance = useCallback(async () => {
    try {
      const userAllowance = await walletContract.allowance(address, tokenData.contractAddress);
      setAllowance(userAllowance);
      // console.log('user allowance: ', ethers.utils.formatUnits(userAllowance, tokenData.formatUnits));
      if (amount !== '') {
        const parsedAmount = ethers.utils.parseUnits(amount, tokenData.formatUnits);
        if (userAllowance.lt(parsedAmount) || parsedAmount.isZero()) {
          setIsApproved(false);
        } else {
          setIsApproved(true);
        }
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.error('get allowance error:', error);
    }
  }, [walletContract, address, tokenData.contractAddress, tokenData.formatUnits, amount]);

  useEffect(() => {
    getUserAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const approval = async () => {
    let parsedAmount;

    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(parseFloat(realAmount).toFixed(4), 6);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(allowance)) {
      try {
        const approvalTransaction = await walletContract.approve(
          tokenData.contractAddress,
          ethers.constants.MaxUint256
        );
        await approvalTransaction.wait();
        const newAllowance = await walletContract.allowance(address, tokenData.contractAddress);
        // console.log('amount::', amount, ethers.utils.parseUnits(amount, 6))
        // console.log('new allowance::', newAllowance, newAllowance.lt(ethers.utils.parseUnits(amount, 6)))
        setAllowance(newAllowance);
        setIsApproved(true);
      } catch (e) {
        enqueueSnackbar('Approval failed.', {
          variant: 'error',
        });
        setIsApproved(false);
        return;
      }
    }
  };

  const deposit = async () => {
    // if (isApproved === false) {
    //   enqueueSnackbar('Please approve transaction first before depositing', {
    //     variant: 'error',
    //   });
    //   return;
    // }
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount, 6);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(userWalletBalance)) {
      enqueueSnackbar('Insufficient balance', {
        variant: 'error',
      });
      return;
    }

    const estimatedCost = await estimateGasUnits('deposit', parsedAmount);

    try {
      //console.log('estimated costs: ', estimatedCost.toNumber());
      const gasUnits = estimatedCost.eq(0) ? 0 : estimatedCost.toNumber() + 1e4; ///@dev keep 1e4 as buffer

      const tx = await inAppContract.deposit(parsedAmount, { gasLimit: gasUnits === 0 ? 1e6 : gasUnits });

      const receipt = await tx.wait();
      // const url = `https://arbiscan.io/tx/${receipt.transactionHash}`;
      // enqueueSnackbar(<a href={{ url }}>View transaction</a>, {
      //   variant: 'success',
      // });
      await getUserWalletBalance();
      await getUserPepeBalance();
      const updatedCredits = userPepeBalance.add(
        ethers.utils.parseUnits(String(parseFloat(amount).toFixed(4)), tokenData.formatUnits)
      );

      setYourCredits(ethers.utils.formatUnits(updatedCredits, tokenData.formatUnits));
      window.location.reload();
      enqueueSnackbar(`Deposit successful, tx hash: ${receipt.transactionHash}`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error unable to complete deposit.', {
        variant: 'error',
      });
      return;
    }
  };

  const withdraw = async () => {
    let parsedAmount;
    if (typeof amount !== 'object') {
      const realAmount = amount === '' ? '0' : amount;
      parsedAmount = ethers.utils.parseUnits(realAmount, 6);
    } else {
      parsedAmount = amount;
    }

    if (parsedAmount.gt(userPepeBalance)) {
      enqueueSnackbar('Insufficient balance', {
        variant: 'error',
      });
      return;
    }
    if (parsedAmount.eq(0)) {
      enqueueSnackbar('Invalid amount', {
        variant: 'error',
      });
      return;
    }

    const estimatedCost = await estimateGasUnits('withdraw', parsedAmount);

    try {
      //console.log('estimated costs: ', estimatedCost.toNumber());
      const gasUnits = estimatedCost.eq(0) ? 0 : estimatedCost.toNumber() + 1e4; ///@dev keep 1e4 as buffer

      const tx = await inAppContract.withdraw(parsedAmount, { gasLimit: gasUnits === 0 ? 1e6 : gasUnits });

      const receipt = await tx.wait();
      // const url = `https://arbiscan.io/tx/${receipt.transactionHash}`;
      // enqueueSnackbar(<a href={{ url }}>View transaction</a>, {
      //   variant: 'success',
      // });
      await getUserWalletBalance();
      await getUserPepeBalance();
      const updatedCredits = userPepeBalance.sub(parsedAmount);
      setYourCredits(ethers.utils.formatUnits(updatedCredits, 6));
      enqueueSnackbar(`Withdrawal successful, tx hash: ${receipt.transactionHash}`, {
        variant: 'success',
      });
      //  window.location.reload();
      setDoneWithdrawing(true);
    } catch (e) {
      console.error('Withdrawal error: ', e);
      enqueueSnackbar('Withdrawal failed', { variant: 'error' });
      return;
    }
  };

  const handleCustomAmountChange = async (percentage, what) => {
    if (what === 'Deposit') {
      const newAmount = userWalletBalance.mul(percentage).div(1e4);
      const formattedAmount = ethers.utils.formatUnits(newAmount, tokenData.formatUnits);
      setAmount(formattedAmount);
      setFormattedAmount(formattedAmount);
    } else {
      const newAmount = userPepeBalance.mul(percentage).div(1e4);
      const formattedAmount = ethers.utils.formatUnits(newAmount, tokenData.formatUnits);
      setAmount(formattedAmount);
      setFormattedAmount(formattedAmount);
    }
  };

  // useEffect(() => {
  //   console.log('allowance::', amount, ethers.utils.parseUnits(amount, 6))
  //   console.log('allowance::', allowance.lt(ethers.utils.parseUnits(amount, 6)))
  // }, [allowance])

  useEffect(() => {
    getUserWalletBalance();
    getUserPepeBalance();
    getUserAllowance();

    //Setting wehen depositing is done using state from depositWithdrawOperation
    if (depositWithdrawOperation === 'deposit') {
      setIsDepositing(true);
    } else {
      setIsDepositing(false);
    }
  }, [getUserAllowance, getUserPepeBalance, getUserWalletBalance, isDepositing, depositWithdrawOperation]);

  const toggleDepositTabOn = () => {
    setIsDepositing(true);
    setDepositWithdrawOperation('deposit');
  };

  const toggleWithdrawTabOn = () => {
    setIsDepositing(false);
    setDepositWithdrawOperation('withdraw');
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full transition-all transform">
                <div className="relative">
                  <div>
                    <GradientBox
                      classes="relative w-[87%] sm:w-1/2 sm:min-w-[37rem] max-w-[40rem] mx-auto py-12 barlow"
                      roundedClass="rounded-[0.625rem]"
                    >
                      {!doneWithdrawing && (
                        <div className="text-white py-[1.875rem] sm:pt-[1.375rem] sm:pb-[3.5rem] px-5 sm:px-[3.5rem]">
                          <h2 className='gradient-text font-["Tourney"] font-bold text-[1.75rem] sm:text-[3rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.03em] text-center mb-5 sm:mb-[30mb]'>
                            MOVE YOUR FUNDS
                          </h2>

                          <p className="font-manrope text-[21px] font-semibold">
                            Pepe's Game has levelled up and migrated!
                          </p>

                          <p className="mt-5 mb-[30px] text-left font-manrope font-medium">
                            {`Prior to exploring the exciting enhancements of Counter Pepe and Time Extension, please ensure you withdraw any remaining funds from here and re-deposit them on Pepe’s new game page. Your staking and locking activities remain unaffected, and your balances will be displayed on the new page.`}
                          </p>

                          <div className="dwf-inner-box min-h-[200px]">
                            <div className="hidden sm:flex justify-center gap-[1.875rem] sm:gap-4 barlow font-bold text-[1.125rem] leading-[1.375rem] sm:text-[18px] sm:leading-5 mb-[1.875rem] sm:mb-8">
                              <span
                                onClick={toggleWithdrawTabOn}
                                className={isDepositing ? 'dwf-inactive-tab' : 'dwf-active-tab'}
                              >
                                Withdraw
                              </span>
                            </div>
                            <div className="flex justify-between text-xs leading-[0.625rem] sm:text-base mb-[0.438rem] sm:mb-2">
                              <div className="">
                                <span className="text-white/70">Wallet: </span>
                                {formattedUserWalletBalance} <span className="uppercase ">{depositWithdrawToken}</span>
                              </div>
                              <div className="">
                                <span className="text-white/70">In-App: </span>
                                {formattedUserPepeBalance} <span className="uppercase ">{depositWithdrawToken}</span>
                              </div>
                            </div>
                            <div className="relative flex mb-[1.875rem] sm:mb-6 sm:manrope">
                              <input className="dwf-funds-input" onChange={handleChange} value={formattedAmount} />
                              <div className="dwf-funds-percentages">
                                <div
                                  onClick={() => {
                                    handleCustomAmountChange(3600, isDepositing ? 'Deposit' : 'Withdraw');
                                  }}
                                  className="text-[0.688rem] text-sm cursor-pointer pointer-events-auto font-light bg-[#18adfa80] rounded-[4px] flex w-[2.438rem] py-1 justify-center"
                                >
                                  36%
                                </div>
                                <div
                                  onClick={() => {
                                    handleCustomAmountChange(6900, isDepositing ? 'Deposit' : 'Withdraw');
                                  }}
                                  className="text-[0.688rem] text-sm cursor-pointer pointer-events-auto font-light bg-[#18adfa80] rounded-[4px] flex w-[2.438rem] py-1 justify-center"
                                >
                                  69%
                                </div>
                                <div
                                  onClick={() => {
                                    handleCustomAmountChange(1e4, isDepositing ? 'Deposit' : 'Withdraw');
                                  }}
                                  className="text-[0.688rem] text-sm cursor-pointer pointer-events-auto font-light bg-[#18adfa80] rounded-[4px] flex w-[2.438rem] py-1 justify-center"
                                >
                                  Max
                                </div>
                              </div>
                            </div>

                            <div className={`flex barlow justify-center`}>
                              {isDepositing ? (
                                <>
                                  {/* {allowance.lt(amount != '' ? typeof amount === "string" ? ethers.utils.parseUnits(parseFloat(amount).toFixed(4), 6) : amount : ethers.utils.parseUnits('5', 6)) ? ( */}
                                  <div className={`basis-[48%]`}>
                                    <div className="relative">
                                      <div className={`${isApproved && 'opacity-40'} z-10 relative`}>
                                        <div className="items-center justify-center sm:flex barlow">
                                          <button
                                            onClick={async () => {
                                              await approval();
                                            }}
                                            disabled={isApproved}
                                            className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                          >
                                            <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                              Approve
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* ) : ( */}
                                  <div className={` basis-[48%]`}>
                                    <div className="relative">
                                      <div className={`${!isApproved && 'opacity-40'} z-10 relative`}>
                                        <div className="items-center justify-center sm:flex barlow">
                                          <button
                                            onClick={async () => {
                                              await deposit();
                                            }}
                                            disabled={!isApproved}
                                            className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                          >
                                            <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                              Deposit
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* )} */}
                                </>
                              ) : (
                                <div className="basis-[48%]">
                                  <div className="relative">
                                    <div className="relative z-10">
                                      <div className="items-center justify-center sm:flex barlow">
                                        {isConnected ? (
                                          <button
                                            onClick={async () => {
                                              await withdraw();
                                            }}
                                            className="relative connect-wallet-gradient-shadow flex justify-center min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0 disabled:opacity-40"
                                          >
                                            <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                              Withdraw
                                            </span>
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() => setConnectModal(true)}
                                            className="relative connect-wallet-gradient-shadow flex justify-center min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0 disabled:opacity-40"
                                          >
                                            <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                              Connect Wallet
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <p className="text-[#00B7F0] font-manrope font-medium mt-[30px]">
                            {`When you're all set, head over to this link: `}{' '}
                            <a
                              href="https://app.pepesgame.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline text-[#18ADFA]"
                            >
                              app.pepesgame.com
                            </a>
                          </p>
                        </div>
                      )}

                      {doneWithdrawing && (
                        <div className="text-white py-[1.875rem] sm:pt-[1.375rem] sm:pb-[50px] px-5 sm:px-[3.5rem]">
                          <h2 className='gradient-text font-["Tourney"] font-bold text-[50px] sm:text-[3rem] leading-[1.925rem] sm:leading-[4.606rem] tracking-[0.03em] text-center mb-5 sm:mb-[30mb]'>
                            SUCCESS!
                          </h2>

                          <p className="font-manrope font-medium">You have successfully withdrawn your funds.</p>

                          <div className="mt-[30px] mb-[50px]">
                            <p className="font-manrope font-medium">
                              {`Now head over to this link`}
                              <a
                                href="https://app.pepesgame.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="underline text-[#00B7F0]"
                              >
                                : app.pepesgame.com
                              </a>
                            </p>
                            <p className="font-manrope font-medium">
                              and make a deposit to experience our new features.
                            </p>
                          </div>

                          <div className={` basis-[48%]`}>
                            <div className="relative">
                              <div className={` z-10 relative`}>
                                <div className="items-center justify-center sm:flex barlow">
                                  <a
                                    href="https://app.pepesgame.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`disabled:cursor-not-allowed relative connect-wallet-gradient-shadow flex justify-center min-w-[8rem] sm:min-w-[12rem] mx-auto sm:mx-0 gap-[0.625rem] sm:gap-0 border-[1px] border-solid border-[#18ADFA] rounded-md bg-[#09031D]/80 p-2 sm:py-[0.688rem] mb-[0.875rem] sm:mb-0`}
                                  >
                                    <span className="button-gradient-text font-bold text-[1.063rem] leading-5">
                                      Let's play
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </GradientBox>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MissingFunds;
