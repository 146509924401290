import './Navbar.css';
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useAccount } from 'wagmi';
import ConnectIcon from '../ConnectIcon';
import { StoreContext } from '../../utils/store';
import QuestIcon from '../QuestIcon';
import NotificationIcon from '../NotificationIcon';
import OptionsIcon from '../OptionsIcon';
import { gold_coin_crown, menu, pepe_wearing_crown, pills, pls_airdrop, tokenPEPE, tokenUSDC, wallet_icon } from '../../assets';
import infoIcon from '../../assets/icons/info.svg'
import { Link, useNavigate } from 'react-router-dom';
import NewTabIcon from '../NewTabIcon';
import AutoSpinIndicator from '../AutoSpinIndicator';
import { useStorage } from '../../utils/StorageContext';
import { CreditsContext } from '../../utils/creditsContext';
import { tokens } from '../../constants';
import StreakStatusIcon from '../StreakStatusIcon';
import NewQuestStateIcon from '../NewQuestStateIcon';

function Navbar() {
  const store = useContext(StoreContext);
  const creditsContext = useContext(CreditsContext);
  const {
    setConnectModal,
    setSideMenu,
    setDepositWithdrawModal,
    setClaimModal,
    autoSpinVars,
    setAutoSpinVars,
    setDepositWithdrawToken,
    setDepositWithdrawOperation,
    setQuestsModal,
    setWalletMenuPopup
  } = store;
  const { questChips, completedStreakForToday, questStreakCompleted } = creditsContext;
  const { autoSpinData } = useStorage();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showQuestDropdown, setShowQuestDropdown] = useState(false);
  const dropDownRef = useRef(null);

  const handleMouseLeave = () => {
    const dropdownElement = dropDownRef.current;

    if (dropdownElement && !dropdownElement.contains(document.activeElement)) {
      setShowProfileDropdown(false);
      setShowQuestDropdown(false)
    }
  };

  const { isConnected, address } = useAccount();
  const isNotMobile = useMediaQuery('(min-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 640px)');
  const formatter = new Intl.NumberFormat('en-US');
  const { betCurrency, setBetCurrency, autoplay, setAutoplay } = useStorage();

  const toggleMusic = () => {
    if (autoplay === 'on') {
      setAutoplay('off');
    } else if (autoplay === 'off') {
      setAutoplay('on');
    }
  };

  const openDepositWithdrawal = (operation) => {
    setDepositWithdrawOperation(operation);
    if (betCurrency === 'PEPE') {
      setDepositWithdrawToken('pepe');
    } else {
      setDepositWithdrawToken('usdc');
    }
    setDepositWithdrawModal(true);
  };

  const navigate = useNavigate();

  return (
    <nav className="w-full flex justify-between items-center text-white pr-3 sm:px-4 lg:pr-10 relative pt-[1.313rem] barlow z-[100]">
      <button className="p-4 min-w-[58.98px]" onClick={() => setSideMenu(true)}>
        <img src={menu} alt="" />
      </button>
      <div className="flex items-stretch justify-center gap-[1.313rem]">
        {isNotMobile ? (
          <>
            <Menu as="div" className="relative inline-block text-left z-[999]">
              <Menu.Button className="p-4 flex items-center h-full">
                <OptionsIcon />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="pl-5 dropdown-menu-container w-max absolute right-0 mt-[1.313rem] rounded-none rounded-tl-md rounded-b-md shadow-lg">
                  <Menu.Item>
                    <a
                      href="https://twitter.com/PepesGame"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white"
                    >
                      Twitter
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="https://discord.gg/pepesgame"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white"
                    >
                      Discord
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="https://medium.com/@pepesgame1"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white"
                    >
                      Medium
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="https://tinyurl.com/PG-Litepaper"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white pr-10"
                    >
                      Litepaper
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="https://pepes-game.gitbook.io/pepes-docs"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white"
                    >
                      Gitbook
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      href="https://shorturl.at/eguCF"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white"
                    >
                      Terms
                    </a>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu
              as="div"
              className={`relative text-left barlow text-sm flex items-center justify-center ${!isConnected ? 'hidden' : ''
                }`}
              onMouseLeave={handleMouseLeave}
            >
              <Menu.Button onClick={() => setQuestsModal(true)} onMouseEnter={() => setShowQuestDropdown(true)}>
                <span className="flex items-center justify-center">
                  <span>{formatter.format(questChips)}</span>
                  <div className="w-1" />
                  <img className="w-5 h-auto" src={pills} alt="" />

                  <NewQuestStateIcon
                    variant={
                      questStreakCompleted > 0 && completedStreakForToday
                        ? 'ongoing'
                        : questStreakCompleted > 0
                          ? 'warning'
                          : 'inactive'
                    }
                  />
                  {/* <span className='border px-1 rounded-full border-white text-xs -mt-3 text-white'>?</span> */}
                </span>
              </Menu.Button>
              <Transition

                show={showQuestDropdown}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  ref={dropDownRef}
                  className="w-max absolute right-0"
                //onMouseLeave={() => setShowProfileDropdown(false)}
                >
                  <div className="h-[1.313rem]" />
                  <div className="dropdown-menu-container rounded-none rounded-tl-md rounded-b-md shadow-lg">
                    <Menu.Item>
                      <div className='py-[15px] px-5 flex space-x-3 w-[210px] items-start rounded-tl-md rounded-b-md bg-[#43334F]'>
                        <img src={infoIcon} alt="" className='mt-2' />
                        <p className='text-xs font-medium leading-6 items-start'>
                          {
                            questStreakCompleted > 0 && completedStreakForToday
                              ? "Congrats, you already acquired your daily dose. Come back tomorrow to get another daily dose of Pepe's Pills."
                              : questStreakCompleted > 0
                                ? "Make your daily spin to get your dose of Pepe’s Pills."
                                : 'You lost your streak. Make your daily spin to start your streak again and get your dose of Pepe’s Pills.'
                          }
                        </p>
                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            {/* notification hidden for now */}
            {/* <Menu as="div" className="relative inline-block text-left mr-3">
              <Menu.Button className="">
                <NotificationIcon />
              </Menu.Button>
            </Menu> */}

            {/* <Menu
              as="div"
              className={`relative text-left flex justify-center items-center ${!isConnected ? 'hidden' : ''}`}
            >
              <Menu.Button className="quest-glow-hover" onClick={() => setQuestsModal(true)}>
                <QuestIcon
                  variant={
                    questStreakCompleted > 0 && completedStreakForToday
                      ? 'ongoing'
                      : questStreakCompleted > 0
                      ? 'warning'
                      : 'inactive'
                  }
                />
              </Menu.Button>
            </Menu> */}

            {/* bet token */}
            {/* <Menu as="div" className={`relative text-left flex justify-center items-center ${!isConnected ? 'hidden' : ''}`}>
              <Menu.Button className="">
                {tokens.map(token => { if (token.name == betCurrency) return <img src={token?.icon} className="w-7 h-7" /> })}
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="dropdown-menu-container w-[10rem] absolute right-0 px-4 rounded-md shadow-lg">
                  {tokens.map(token => {
                    if (token.trade) {
                      return (
                        <Menu.Item>
                          <button
                            className="group flex w-full items-center justify-center rounded-md h-12 text-sm text-white"
                            onClick={() => setBetCurrency(token.name)}
                          >
                            <span className="mr-1">
                              <img src={token.icon} className="w-4 h-4" />
                            </span>
                            <span>{token.name}</span>
                          </button>
                        </Menu.Item>
                      )
                    }

                    return null
                  }
                  )}
                </Menu.Items>
              </Transition>
            </Menu> */}

            <Menu as="div" className={`relative inline-block text-left ${!isConnected ? 'hidden' : ''}`}>
              <Menu.Button
                onClick={() => navigate('/slot')}
                className="barlow font-semibold text-[0.875rem] leading-[1.125rem] bg-[linear-gradient(99.44deg,rgba(74,59,119,0.42)_16.18%,rgba(50,40,82,0.42)_82.19%)] border-[0.6px] border-solid border-[#886CDD] rounded-md shadow-[0px_0px_5px_rgba(128,0,212,0.71)] px-[1.875rem] py-[0.688rem]"
              >
                Play
              </Menu.Button>
            </Menu>

            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="barlow font-semibold text-[0.875rem] leading-[1.125rem] bg-[linear-gradient(99.44deg,rgba(74,59,119,0.42)_16.18%,rgba(50,40,82,0.42)_82.19%)] border-[0.6px] border-solid border-[#886CDD] rounded-md shadow-[0px_0px_5px_rgba(128,0,212,0.71)] px-[1.875rem] py-[0.688rem]">
                Earn
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="pl-5 dropdown-menu-container w-max absolute right-0 mt-[1.313rem] px-4 rounded-none rounded-tl-md rounded-b-md shadow-lg">
                  {isConnected ? (
                    <>
                      <Menu.Item>
                        <Link
                          to="/stake"
                          state={{ state: 'stake' }}
                          className="group flex w-full items-center justify-start h-12 text-sm text-white"
                        >
                          Stake
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/lock"
                          state={{ state: 'lock' }}
                          className="group flex w-full items-center justify-start h-12 text-sm text-white"
                        >
                          Lock/LP
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          className="pr-10 group flex w-full items-center justify-start rounded-md h-12 text-sm text-white"
                          onClick={() => navigate('/claim', { state: 'claim' })}
                        >
                          Claim Airdrops
                        </button>
                      </Menu.Item>

                      <Menu.Item>
                        <button
                          className="group flex w-full items-center gap-1 justify-start rounded-md h-12 text-sm text-white group"
                          onClick={() => navigate('/claimpls')}
                        >
                          <img src={pls_airdrop} alt="" className="w-4 h-auto group-hover:opacity-60" />
                          {/* <span>Claim Airdrops</span> */}
                          <span>PLS Asset Airdrops</span>
                        </button>
                      </Menu.Item>
                    </>
                  ) : null}
                  <Menu.Item>
                    <a
                      href="https://app.balancer.fi/#/arbitrum/swap/eth/0x4fc2a3fb655847b7b72e19eaa2f10fdb5c2addbe"
                      target="_blank"
                      rel="noreferrer"
                      className="group flex gap-2 w-full items-center justify-start rounded-md h-12 text-sm text-white"
                    >
                      <span>Buy PEG</span>
                      <span>
                        <NewTabIcon />
                      </span>
                    </a>
                  </Menu.Item>

                  {/* <Menu.Item>
                    <button className="group flex w-full items-center justify-start rounded-md h-12 text-sm text-white">
                      Add PEG
                    </button>
                  </Menu.Item> */}
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className={`relative inline-block text-left ${!isConnected ? 'hidden' : ''}`}>
              <Menu.Button className="barlow font-semibold text-[0.875rem] leading-[1.125rem] bg-[linear-gradient(99.44deg,rgba(74,59,119,0.42)_16.18%,rgba(50,40,82,0.42)_82.19%)] border-[0.6px] border-solid border-[#886CDD] rounded-md shadow-[-2px_-2px_14px_#7D23B9,0px_0px_10px_#886CDD,2px_2px_5px_#7D23B9,inset_0px_0px_8px_#886CDD] px-[1.875rem] py-[0.688rem]">
                Campaigns
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className=" absolute pl-5 dropdown-menu-container absolute w-max mt-[1.313rem] right-0 px-4 rounded-none rounded-tl-md rounded-b-md shadow-lg text-left">
                  <Menu.Item>
                    <Link
                      to="/degen-dashboard"
                      className="group flex w-full items-center justify-start h-12 text-sm text-white"
                    >
                      Degens of the Week
                    </Link>
                  </Menu.Item>

                  <Menu.Item>
                    <Link
                      to="/referrals"
                      className="pr-10 group flex w-full items-center justify-start h-12 text-sm text-white"
                    >
                      Bring Your Own Degen
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </>
        ) : null}
        {isConnected ? (
          <>
            <Menu as="div" className="relative inline-block text-left">
              <AutoSpinIndicator show={(autoSpinData.isAuto || false) && isNotMobile} classes={`${autoSpinData.isAuto ? '' : 'hidden'} fixed right-[132px] top-[56px] mt-16 `} />

              <div>
                <Menu.Button
                  onClick={() => setWalletMenuPopup(true)}
                  className="flex items-center justify-center gap-[0.438rem] barlow font-semibold text-[0.875rem] leading-[1.125rem] bg-[linear-gradient(99.44deg,#4A3B77_16.18%,#322852_82.19%)] border-[0.6px] border-solid border-[#886CDD] rounded-md shadow-[0px_0px_5px_rgba(128,0,212,0.71)] px-[1.875rem] py-[0.688rem]"
                >
                  <img src={wallet_icon} alt="" className="w-4 h-auto" />
                  <span>{address.slice(0, 6) + '..'}</span>
                </Menu.Button>
              </div>
            </Menu>

            <Menu
              as="div"
              className={`relative inline-block text-left z-[999]  ${!isConnected || !isNotMobile ? 'hidden' : ''}`}
              onMouseLeave={handleMouseLeave}

            >
              <div
                className="h-full flex items-center justify-center"
                onMouseEnter={() => setShowProfileDropdown(true)}
              >
                <button className="bg-[#09031D] border-[1px] border-solid border-[#7649EE] rounded-full w-10 h-10 flex justify-center items-center overflow-hidden">
                  <img src={pepe_wearing_crown} alt="" className="" />
                </button>
              </div>

              <Transition

                show={showProfileDropdown}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  ref={dropDownRef}
                  className="w-max absolute right-0"
                //onMouseLeave={() => setShowProfileDropdown(false)}
                >
                  <div className="h-[1.313rem]" />
                  <div className="pl-5 dropdown-menu-container rounded-none rounded-tl-md rounded-b-md shadow-lg px-4">
                    <Menu.Item>
                      <button
                        onClick={() => setWalletMenuPopup(true)}
                        className="group flex w-full items-center justify-start h-12 text-sm text-white"
                      >
                        Wallet
                      </button>
                    </Menu.Item>

                    <Menu.Item>
                      <button
                        onClick={() => {
                          setShowProfileDropdown(false);
                          navigate('/recent-plays');
                        }}
                        className="group flex w-full items-center justify-start h-12 text-sm text-white"
                      >
                        Your History
                      </button>
                    </Menu.Item>

                    <Menu.Item>
                      <button
                        onClick={() => {
                          setShowProfileDropdown(false);
                          navigate('/referrals');
                        }}
                        className="group flex w-full items-center justify-start h-12 text-sm text-white"
                      >
                        Your Referrals
                      </button>
                    </Menu.Item>

                    <Menu.Item>
                      <button
                        onClick={() => {
                          setShowProfileDropdown(false);
                          navigate('/degen-dashboard', { state: { myRank: true } });
                        }}
                        className="pr-10 group flex w-full items-center justify-start h-12 text-sm text-white"
                      >
                        Your Degen Rank
                      </button>
                    </Menu.Item>

                    <Menu.Item>
                      <button
                        onClick={() => {
                          setShowProfileDropdown(false);
                          setQuestsModal(true);
                        }}
                        className="group flex gap-2 w-full items-center justify-start h-12 text-sm text-white"
                      >
                        <NewQuestStateIcon
                          variant={
                            questStreakCompleted > 0 && completedStreakForToday
                              ? 'ongoing'
                              : questStreakCompleted > 0
                                ? 'warning'
                                : 'inactive'
                          }
                        />
                        <span>Daily Streak</span>
                      </button>
                    </Menu.Item>

                    <Menu.Item>
                      <button
                        onClick={() => {
                          setShowProfileDropdown(false);
                          setConnectModal(true);
                        }}
                        className="group flex w-full items-center justify-start h-12 text-sm text-white"
                      >
                        Disconnect
                      </button>
                    </Menu.Item>

                    <Menu.Item>
                      <button
                        onClick={() => toggleMusic()}
                        className="group flex w-full items-center justify-start h-12 text-sm text-white"
                      >
                        {autoplay === 'on' ? 'Sound Off' : 'Sound On'}
                      </button>
                    </Menu.Item>

                    {/* <Menu.Item>
                      <a
                        href="https://app.balancer.fi/#/arbitrum/swap/eth/0x4fc2a3fb655847b7b72e19eaa2f10fdb5c2addbe"
                        target="_blank"
                        rel="noreferrer"
                        className="group flex gap-2 w-full items-center justify-center h-12 text-sm text-white"
                      >
                        <span>Buy PEG</span>{' '}
                        <span>
                          <NewTabIcon />
                        </span>
                      </a>
                    </Menu.Item> */}
                  </div>
                </Menu.Items>
              </Transition>

            </Menu>
          </>
        ) : (
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button
              onClick={() => setConnectModal(true)}
              className="flex items-center justify-center gap-[0.438rem] barlow font-semibold text-[0.875rem] leading-[1.125rem] bg-[linear-gradient(99.44deg,#4A3B77_16.18%,#322852_82.19%)] border-[0.6px] border-solid border-[#886CDD] rounded-md shadow-[0px_0px_5px_rgba(128,0,212,0.71)] px-[1.875rem] py-[0.688rem]"
            >
              <img src={wallet_icon} alt="" className="w-4 h-auto" />
              <span>Connect</span>
            </Menu.Button>
          </Menu>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
