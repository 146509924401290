// New Routes for App
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import ErrorPage from '../pages/error-page';
import Slotmachine from '../pages/slotmachine';
import JackpotMode from '../pages/jackpotmode';
import Chart from '../pages/chart';
import Result from '../pages/result';
import Welcome from '../pages/welcome';
import Connect from '../pages/connect';
import RecentPlays from '../pages/recent-plays';
import LeaderBoard from '../pages/leaderboard'
import Navbar from '../components/Navbar/Navbar';
import Stake from '../pages/stake';
import ReferralPage from '../pages/referral';
import ClaimAirdropPLS from '../pages/claim-airdrop';
import StakePage from '../pages/nstake';
import Share from '../pages/share';

const AppRoutes = () => (
  <Routes>
    {/* <Route path="/connect" element={<Connect />} /> */}
    <Route path="/" element={<Welcome />} />
    {/* <Route path="/slot" element={<Slotmachine />} />
    <Route path="/jackpot" element={<JackpotMode />} />
    <Route path="/chart" element={<Chart />} />
    <Route path="/result" element={<Result />} />
    <Route path="/recent-plays" element={<RecentPlays />} />
    <Route path="/leaderboard" element={<LeaderBoard />} />
    <Route path="/stake" element={<Stake tab='stake' />} />
    <Route path="/referrals" element={<ReferralPage />} />
    <Route path="/lock" element={<Stake tab='lock' />} />
    <Route path="/claimpls" element={<ClaimAirdropPLS />} />
    <Route path="/claim" element={<Stake tab='claim' />} />
    <Route path="/degen-dashboard" element={<StakePage />} />
    <Route path="/share/:Id" element={<Share />} /> */}
    <Route element={<ErrorPage />} />
  </Routes>
);

export default AppRoutes;
