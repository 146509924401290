import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { cancel_icon_w_gradient, smartPepe } from '../../assets';
import { useAccount, useSigner } from 'wagmi';
import { ethers } from 'ethers';
import { enqueueSnackbar } from 'notistack';
import { StoreContext } from '../../utils/store';
import { pepeLockingContractAddress, pepeEsPegLockUpContractAddress } from '../../constants';
import { pepeEsPegLockABI } from '../../utils/pepeEsPegLockABI';
import { pepeLock } from '../../utils/pepeLock';

const ClaimLockModal = ({ open, isOpen, isEsPeg, esPegLockID }) => {
  const Store = useContext(StoreContext);
  const { lockingReward, setIsEsPeg, setEsPegLockID, setRefreshLocking, setLockingReward } = Store;
  const { data: signer } = useSigner();
  const [isComplete, setIsComplete] = useState(false);
  const [formattedInput, setFormattedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [hash, setHash] = useState('');

  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);

  const pepeEsPegLockingContract = useMemo(
    () => new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, signer),
    [signer]
  );

  const pepeLockingContract = useMemo(
    () => new ethers.Contract(pepeLockingContractAddress, pepeLock, signer),
    [signer]
  );

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const valid = floatRegexp.test(value.replace(/,/g, ''));
      if (!valid) e.preventDefault();
      if (valid) {
        const formatted = value.replace(/,/g, '');
        setAmount(value.replace(/,/g, '') || '');
        setFormattedAmount(formatted);
      }
      if (value === '') {
        setAmount('');
        setFormattedAmount('');
      }
    },
    [floatRegexp]
  );

  useEffect(() => {
    setFormattedAmount(lockingReward);
    setAmount(lockingReward);
  }, [lockingReward]);

  const handleClaimPegReward = async () => {
    let estimateGas;
    try {
      estimateGas = await pepeLockingContract.estimateGas.claimUsdcRewards();
      estimateGas = estimateGas.toNumber() + 1e4;
    } catch (error) {
      estimateGas = 3e6;
    }
    try {
      const tx = await pepeLockingContract.claimUsdcRewards({ gasLimit: estimateGas });
      const receipt = await tx.wait();
      setHash(receipt.transactionHash);
      setIsComplete(true);
      setRefreshLocking(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Claiming failed.', {
        variant: 'error',
      });
      return;
    }
  };

  const handleClaimEsPegReward = async () => {
    let estimateGas;
    try {
      estimateGas = await pepeEsPegLockingContract.estimateGas.claimUsdcRewards(esPegLockID);
      estimateGas = estimateGas.toNumber() + 1e4;
    } catch (error) {
      estimateGas = 3e6;
    }
    try {
      const tx = await pepeEsPegLockingContract.claimUsdcRewards(esPegLockID, { gasLimit: estimateGas });
      const receipt = await tx.wait();
      setRefreshLocking(false);
      setHash(receipt.transactionHash);
      setIsComplete(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Claiming failed.', {
        variant: 'error',
      });
      return;
    }
  };

  const handleCloseModal = () => {
    setEsPegLockID(0);
    setHash('');
    setRefreshLocking(false);
    setIsEsPeg(false);
    setLockingReward('0');
    open(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`modal-dialog transition-all transform ${
                  isComplete ? 'w-[739px] h-[600px]' : 'w-[628px] h-[344px]'
                } stake-modal flex max-[480px]:w-4/5 relative`}
              >
                <div className="flex flex-col justify-around w-full mx-auto py-2">
                  <div className="flex items-end justify-end mr-4">
                    <button onClick={() => handleCloseModal()}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                    </button>
                  </div>
                  {isComplete ? (
                    <>
                      <div className='flex items-center justify-center text-7xl font-["Tourney"] font-extrabold gradient-text text-center'>
                        YEAH!
                      </div>
                      <div className="mb-40 text-center text-white barlow">
                        <p className="text-3xl">You have successfully claimed</p>
                        <p className='text-[43px] font-["Tourney"] font-extrabold gradient-text'>
                          {formattedInput} {isEsPeg ? 'PEG' : 'USDC'}
                        </p>
                        <p className="text-3xl">to your frenly dealer</p>
                        <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + hash}>
                          See tx
                        </a>
                      </div>
                      <img src={smartPepe} className="absolute bottom-0 left-0" />
                    </>
                  ) : (
                    <>
                      <div className='flex items-center justify-center text-[33px] font-["Tourney"] font-extrabold gradient-text text-center uppercase'>
                        {isEsPeg ? 'Claim vested peg' : 'Claim USDC REWARDS'}
                      </div>

                      <>
                        <div className="border-border-blue border flex flex-col items-center justify-between w-4/5 px-4 py-5 mx-auto text-white bg-deep-blue/70 stake-modal-shadow rounded-xl barlow sm:flex-row">
                          <p className="text-[#E0DDFF] text-sm">
                            <span className="mr-1">{isEsPeg ? 'PEG' : 'USDC'}</span>
                          </p>
                          <div className="flex items-center w-auto sm:w-1/2 justify-evenly">
                            {/* <p className='text-xl'>1,000,000.00</p> */}
                            <input className="dwf-funds-input-dark" disabled onChange={handleChange} value={formattedInput} />
                          </div>
                        </div>
                      </>

                      <div className="flex justify-center text-white text-xs">
                        <p>You are claiming: </p>
                        <p>
                          {formattedInput} {isEsPeg ? 'PEG' : 'USDC'}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <button
                          disabled={formattedInput <= 0}
                          onClick={() => (isEsPeg ? handleClaimEsPegReward() : handleClaimPegReward())}
                          className={`inline-block text-white bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[170px] text-xs inter mx-4 ${
                            formattedInput <= 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                          }`}
                        >
                          {' '}
                          Send to my Wallet, Ser!{' '}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ClaimLockModal;
