import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useStorage } from '../utils/StorageContext';
import axios from 'axios';
import { api, approvedAssets } from '../constants';
import { CreditsContext } from '../utils/creditsContext';
import { getSelectedTokenData } from '../services/service';
import { StoreContext } from '../utils/store';
import { useAccount } from 'wagmi';
import { useSnackbar } from 'notistack';

export function useAutoSpinTradeService() {
    const { address } = useAccount();
    const { autoSpinData, setIsRunning, setAutoSpinData, betCurrency } = useStorage();
    const Store = useContext(StoreContext);
    const {
        setGoodLuckModal,
        tradeVars,
        setAutoSpinVars,
        setStoreState,
        setAutoSpinResult,
        setTradeVars
    } = Store;
    const prevRemainingTime = useRef(); // To store the previous remaining time
    const prevAutoSpinId = useRef();
    const isPlayRef = useRef();
    const isStopRef = useRef();
    const isStopAtRef = useRef();
    const [timeLeft, setTimeLeft] = useState(tradeVars.time - 2);
    const intervalRef = useRef(); // To store the interval object for the checking bet details
    const creditsCtx = useContext(CreditsContext);
    const { getQuestChips, yourCredits } = creditsCtx;
    const { enqueueSnackbar } = useSnackbar();
    const [count, setCount] = useState(0);

    useEffect(() => {
        prevRemainingTime.current = tradeVars?.time || 0;
    }, [])


    const randomizer = async () => {
        const betDurations = [20, 30, 45, 60];
        const isLongs = ['Long', 'Short'];
        const assets = ["WBTC", "WETH"];
        const chosenAsset = assets[Math.floor(Math.random() * assets.length)];

        setTradeVars(
            tradeVars.amount,
            chosenAsset === "WBTC" ? 'BTC' : 'ETH',
            betDurations[Math.floor(Math.random() * betDurations.length)],
            isLongs[Math.floor(Math.random() * isLongs.length)]
        )
    };

    const sendOpenTrade = useCallback(async (currentAutoSpinId, whatsLeft, stop, stopAt) => {
        /// to lambda since values are generated instantly, only animations delay

        const { isAuto, amount, rounds, roundsLeft, autospin_id, isPlay, isStop, isStopAt } = autoSpinData;
        // console.log('isPlay value::', autoSpinData.isPlay)
        if (currentAutoSpinId === autoSpinData.autospin_id) {
            return;
        }
        // console.log('vvvvv1::', yourCredits)
        if (yourCredits >= tradeVars.amount) {
            try {
                await randomizer()

                const data = await lambdaPlaceBet(tradeVars.amount, tradeVars.currency, tradeVars.time, tradeVars.longShort, currentAutoSpinId);
                //setLambdaReturn(data)
                // setStoreState('lambdaResponseData', data);

                // const whatsLeft = roundsLeft - 1;
                // var values = autospin_id.split("_").filter(item => /^\d+$/.test(item));
                // let id = autospin_id.replace(values.join("_"), "").slice(0, -1)
                if (currentAutoSpinId !== autoSpinData.autospin_id) {
                    setAutoSpinData({ isAuto, amount, rounds, roundsLeft: whatsLeft, autospin_id: currentAutoSpinId, isPlay, currentBetId: data.betId, currentBetStartTime: data.startTime, isStop: stop, isStopAt: stopAt });
                    setAutoSpinVars(autoSpinData.isAuto, autoSpinData.amount, autoSpinData.rounds, whatsLeft);
                }
                return data;
            } catch (e) {
                console.log({ sendTradeError: e });
                if (autoSpinData.isAuto) {
                    const { isAuto, amount, rounds, roundsLeft, autospin_id, isPlay, isStop, isStopAt } = autoSpinData;
                    const whatsLeft = roundsLeft + 1;
                    var values = autospin_id.split("_").filter(item => /^\d+$/.test(item));
                    let id = autospin_id.replace(values.join("_"), "").slice(0, -1)
                    setAutoSpinData({ isAuto, amount, rounds, roundsLeft: whatsLeft, autospin_id: `${id}_${(rounds - whatsLeft)}_${rounds}`, isPlay, isStop, isStopAt });
                    setAutoSpinVars(isAuto, amount, rounds, whatsLeft);
                }
                // navigate('/slot');
                // enqueueSnackbar(e.message, {
                //   variant: 'error',
                //   preventDuplicate: true,
                // });
                return;
            }
        } else {
            // console.log('vvvvvv::', yourCredits)
            enqueueSnackbar('Your are out of Credits. Auto spin cancelled', {
                variant: 'warning',
                preventDuplicate: true,
            });
            setIsRunning(false)
            clearInterval()
        }
    }, [autoSpinData.autospin_id, yourCredits])


    const checkBetAutoSpinBetStatus = useCallback(async () => {
        const { isAuto, amount, rounds, roundsLeft, autospin_id, isPlay, isStop, isStopAt } = autoSpinData;
        console.log('the current autospin data::', autoSpinData)
        isPlayRef.current = autoSpinData.isPlay;
        isStopRef.current = autoSpinData.isStop;
        isStopAtRef.current = autoSpinData.isStopAt;
        setCount(prevCount => prevCount + 1);
        if (count >= 1) {
            try {
                setIsRunning(true)
                const interval = setInterval(async () => {
                    try {
                        const lambdaResponse = await axios.post(api.prod.trade_API, { betId: autoSpinData.currentBetId });
                        const responseBody = lambdaResponse.data;

                        let tleft = tradeVars.time - Math.floor((responseBody.serverTime - autoSpinData.currentBetStartTime) / 1000);

                        console.log('autospin details::', autoSpinData)
                        if (tleft !== prevRemainingTime.current) {
                            setTimeLeft(tleft <= 0 ? 0 : tleft);
                            prevRemainingTime.current = tleft;
                        }

                        if (responseBody.serverTime >= autoSpinData.currentBetStartTime + (tradeVars.time + 1) * 1000) {

                            if (responseBody.data[0].status === 'Settled') {
                                clearInterval(interval);
                                // setIsRunning(false)
                                prevRemainingTime.current = tradeVars?.time || 0;

                                if (autoSpinData.roundsLeft === 0) {
                                    setIsRunning(false)
                                    clearInterval(interval);
                                    setCount(0)
                                } else {
                                    // console.log('current autospin data from settled::', autoSpinData, isPlayRef)

                                    const whatsLeft = roundsLeft - 1;
                                    var values = autospin_id.split("_").filter(item => /^\d+$/.test(item));
                                    let id = autospin_id.replace(values.join("_"), "").slice(0, -1)
                                    let currentAutoSpinId = `${id}_${(rounds - whatsLeft)}_${rounds}`


                                    // setAutoSpinData({ isAuto, amount, rounds, roundsLeft: whatsLeft, autospin_id: `${id}_${(rounds - whatsLeft)}_${rounds}`, isPlay, isStop, isStopAt });
                                    // setAutoSpinVars(autoSpinData.isAuto, autoSpinData.amount, autoSpinData.rounds, whatsLeft);

                                    console.log('isStop before sendOpenTrade::', isStopRef.current, isStopAtRef.current, roundsLeft, isPlayRef.current)
                                    if (isStopRef.current && isStopAtRef.current === roundsLeft) {
                                        setCount(0)
                                        enqueueSnackbar('Autospin cancelled', {
                                            variant: 'warning',
                                            preventDuplicate: true,
                                        });
                                        setIsRunning(false);
                                        setTimeout(() => {
                                            setAutoSpinResult(true)
                                        }, 2500);
                                    } else {

                                        console.log('isPlay before sendOpenTrade::', currentAutoSpinId, prevAutoSpinId.current)
                                        if (isPlayRef.current) {
                                            if (currentAutoSpinId !== prevAutoSpinId.current) {
                                                prevAutoSpinId.current = currentAutoSpinId;
                                                sendOpenTrade(currentAutoSpinId, whatsLeft, isStopRef.current, isStopAtRef.current);
                                            }
                                        } else {
                                            setIsRunning(false)
                                            enqueueSnackbar('Autospin Paused', {
                                                variant: 'warning',
                                                preventDuplicate: true,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        ///keep fetching
                        console.error('get bet status interval error: ', e);
                    }
                }, 700);

                intervalRef.current = interval;
            } catch (err) {
                setIsRunning(false)
            }
        }
    }, [autoSpinData.currentBetId, autoSpinData.autospin_id, sendOpenTrade, autoSpinData.isPlay, autoSpinData.isStop])

    const stopInterval = useCallback(() => {
        clearInterval(intervalRef.current);
    }, []);


    const lambdaPlaceBet = async (betAmount, assetTicker, betDuration, isLong, currentAutoSpinId) => {
        const lambdaParams = {
            userAddress: address,
            betAmount: betAmount,
            betDuration: betDuration,
            asset:
                assetTicker === 'BTC' ? approvedAssets.WBTC : assetTicker === 'ETH' ? approvedAssets.WETH : approvedAssets.ARB,
            assetTicker: assetTicker,
            symbolConcat: `${assetTicker}-USD`,
            isLong: isLong === 'Long' ? true : false,
            autospin_id: currentAutoSpinId
        };

        try {
            RunQuestsCalculation();
            getQuestChips();
            //! update API_Gateway
            const lambdaResponse = await axios.post(getSelectedTokenData(betCurrency)?.apis.prod.prod_API, lambdaParams);
            const data = lambdaResponse.data?.message; //unsure of returned data type, assuming json
            return data;
        } catch (e) {
            console.error('Failed to place bet: ', e);

            // logFaileBet(e.toString())
            // navigate("/slot");
            // enqueueSnackbar('Bet was not placed successfully. ', { variant: 'error' });
            // throw new Error('Failed to place bet');
        }
    };

    const RunQuestsCalculation = async () => {
        const { data } = await axios.post(`https://4huqqpgjld.execute-api.us-west-2.amazonaws.com/addUpdatev2?address=${address}`);
        if (data.data.hasSpunToday) {
            await getQuestChips();
        }
    };




    return {
        checkBetAutoSpinBetStatus,
        lambdaPlaceBet,
        RunQuestsCalculation,
        sendOpenTrade,
        stopInterval
    };
}