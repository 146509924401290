import React, { useState, createContext, useContext, useEffect, useMemo } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const StorageContext = createContext();

export const StorageContextProvider = ({ children }) => {
  const { localStorageData, setLocalStorageData, removeLocalStorageData } = useLocalStorage('autoSpin', {});
  const betCurrency = useLocalStorage('betCurrency', 'USDC');
  const autoplay = useLocalStorage('music', 'on')
  const volume = useLocalStorage('volume', 1)
  const agreed = useLocalStorage('agreed', false)
  const isSpinning = useLocalStorage('isSpinning', false);
  const isRunning = useLocalStorage('isRunning', false);
  const isAutoWinning = useLocalStorage('isAutoWinning', true);

  const contextValue = useMemo(() => ({
    autoSpinData: localStorageData,
    removeAutoSpinData: () => removeLocalStorageData(),
    setAutoSpinData: (value) => setLocalStorageData(value),
    betCurrency: betCurrency.localStorageData,
    setBetCurrency: (value) => betCurrency.setLocalStorageData(value),
    removeBetCurrency: () => betCurrency.removeLocalStorageData(),
    autoplay: autoplay.localStorageData,
    setAutoplay: (value) => autoplay.setLocalStorageData(value),
    removeAutoplay: () => autoplay.removeLocalStorageData(),
    vol: volume.localStorageData,
    setVol: (value) => volume.setLocalStorageData(value),
    removeVol: () => volume.removeLocalStorageData(),
    agreed: agreed.localStorageData,
    setAgreed: (value) => agreed.setLocalStorageData(value),
    removeAgreed: () => agreed.removeLocalStorageData(),
    isSpinning: isSpinning.localStorageData,
    setIsSpinning: (value) => isSpinning.setLocalStorageData(value),
    removeIsSpinning: () => isSpinning.removeLocalStorageData(),
    isRunning: isRunning.localStorageData,
    setIsRunning: (value) => isRunning.setLocalStorageData(value),
    removeIsRunning: () => isRunning.removeLocalStorageData(),
    isAutoWinning: isAutoWinning.localStorageData,
    setIsAutoWinning: (value) => isAutoWinning.setLocalStorageData(value),
    removeIsAutoWinning: () => isAutoWinning.removeLocalStorageData(),
  }));

  return <StorageContext.Provider value={contextValue}>{children}</StorageContext.Provider>;
};

export default StorageContextProvider;

export const useStorage = () => {
  return useContext(StorageContext);
};
