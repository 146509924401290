import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { api, addreferURL } from '../../constants';
import { useConnect, useAccount } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import { cancel_icon_w_gradient, copyicon, editicon } from '../../assets';
import GradientBox from '../../../src/components/GradientBox/GradientBox';
import '../../styles/referral.css';
import LoaderIcon from '../LoaderIcon';
import { StoreContext } from '../../utils/store';
import { useSnackbar } from 'notistack';

export default function MyModal({ open, isOpen }) {
    const referralURL = window.location.origin;
    const store = useContext(StoreContext);
    const {
        setReferralModal,
    } = store;
    const navigate = useNavigate();
    const { address } = useAccount();
    const [loading, setLoading] = useState(false)
    const [isEditingReferrer, setIsEditingReferrer] = useState(false);
    const [referrerText, setReferrerText] = useState('Fill in the code of your referrer');
    const [referralCode, setReferralCode] = useState('')
    const [_event, setInputEvent] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    //Method to close connect Wallet Modal
    const closeModal = () => {
        open(false);
        // navigate('/slot');
    };

    useEffect(() => {
        getReferralCode()
    }, [address]);


    // Method to handle edit referrer button
    const handleEditReferrerButton = () => {
        if (!isEditingReferrer) {
            setReferrerText('')
        }
        setIsEditingReferrer(!isEditingReferrer);
    };

    const handleSubmit = async () => {
        // console.log('input text::', referrerText)
        try {

            let regex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm
            if(referrerText.length > 32 || regex.test(referrerText)){
              enqueueSnackbar('Please input only the referer code', {
                variant: 'error',
              });
              return 
            }


            const response = await axios.get(`${addreferURL}refCode=${referrerText}&referee=${address}`);
            const data = response.data; //unsure of returned data type, assuming json
            if (data.status == "success") {
                enqueueSnackbar('Referrer linked successfully', {
                    variant: 'success',
                });
                setReferralModal(false)
            } else {
                enqueueSnackbar(data?.message, {
                    variant: 'error',
                });
            }
        } catch (e) {
            console.error('Failed to check for referralCode: ', e);
            throw new Error('Failed to check for referralCode');
        } finally {
            setLoading(false)
        }
    }

    const handleReferrerTextChange = (e) => {
        setReferrerText(e.target.value);
    };

    //Method to check if user is already having a referral code 
    const getReferralCode = async () => {
        if(address){
            try {
                const response = await axios.get(`${api.prod.prod_API}/refercode?address=${address}`);
                const data = response.data.refcode[0]; //unsure of returned data type, assuming json
                console.log('Referral data::', data)
                setReferralCode(data.refcode)
                return data;
            } catch (e) {
                console.error('Failed to check for referralCode: ', e);
                throw new Error('Failed to check for referralCode');
            }
        }
    };


    const copyToClipboard = () => {
        try {
            navigator.clipboard.writeText(`${referralURL}?ref=${referralCode}`);
            enqueueSnackbar('Referal link copied to clipboard.', {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar('An error occurred while copying the referral link.', { variant: 'error' });
        }
    };


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-90" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="transform transition-all relative w-full">
                                <div className="relative">
                                    <div>
                                        <GradientBox
                                            classes="relative w-[87%] sm:w-1/2 sm:min-w-[37rem] max-w-[40rem] mx-auto py-12"
                                            roundedClass="rounded-[0.625rem]"
                                        >
                                            <div className="text-white py-[1.875rem] sm:pt-[1.375rem] sm:pb-[1.75rem] px-5 sm:px-[2.188rem]">
                                                <div className="flex justify-end mb-5 sm:-[0.688rem]">
                                                    <button onClick={closeModal}>
                                                        <img src={cancel_icon_w_gradient} alt="cancel" className="w-4 h-auto sm:w-auto" />
                                                    </button>
                                                </div>

                                                <div>
                                                    <div className='flex flex-col text-center sm:flex-row font-["Tourney"] gradient-text font-bold text-[1.75rem] sm:text-[3.125rem] justify-center items-center mb-4'>
                                                        <span>FIRST TIME, FREN?</span>
                                                    </div>

                                                    <div className='md:w-[450px] mx-auto'>
                                                        <p className='text-[11px] sm:text-[14px] leading-[20px] font-["Manrope"] font-medium'>They say you never forget your first.</p>
                                                        <p className='text-[11px] sm:text-[14px] leading-[20px] font-["Manrope"] flex mt-3 mb-5 font-medium'>Before you place that memorable spin, do you have a degenerate friend to thank for introducing you to the thrill of Pepe's Game?</p>

                                                        <div className="sm:flex-1 bg-[rgba(255,255,255,0.05)] rounded-2xl h-[80px] flex items-center justify-center px-4 sm:px-0 md:w-[345px] mx-auto">
                                                            <div className="text-center">
                                                                <div className="text-[#E0DDFF] text-base lg:text-base font-light font-['Barlow']">WHO BROUGHT YOU?</div>
                                                                <div className="text-[rgba(224,221,255,0.7)] text-[15px] lg:text-[15px] font-medium flex items-center justify-center gap-2">

                                                                    {isEditingReferrer ? (
                                                                        <input
                                                                            autoFocus
                                                                            type="text"
                                                                            value={referrerText}
                                                                            className='bg-transparent border-0'
                                                                            onChange={e => { setInputEvent(e); setReferrerText(e.target.value) }}
                                                                            onBlur={handleEditReferrerButton}
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            href={`slot?ref=${referralCode}`}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="lg:no-underline lg:hover:underline lg:hover:cursor-pointer"
                                                                        >
                                                                            {referrerText}
                                                                        </span>
                                                                    )}

                                                                    <button className="lg:cursor-pointer py-2 px-1" onClick={handleEditReferrerButton}>
                                                                        <img width={16} src={editicon} alt="" />
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='flex mx-auto justify-center gap-4 mt-6'>
                                                            <button
                                                                className="modal-btn font-['Barlow'] modal-submit-btn text-white inline-block text-border-blue inter py-1 rounded-md stake-btn-shadow w-[135px] text-[17px] inter mr-2"
                                                                onClick={handleSubmit}
                                                            >
                                                                Submit
                                                            </button>
                                                            <button
                                                                className="modal-btn font-['Barlow'] modal-skip-btn inline-block text-white text-border-blue bg-deep-blue/40 inter py-1 px-4 rounded-md stake-btn-shadow w-[135px] text-[17px] font-bold inter"
                                                                onClick={closeModal}
                                                            >
                                                                Skip
                                                            </button>
                                                        </div>


                                                        <div className='mt-[50px]'>
                                                            <p className='font-["Barlow"] text-[#6b6877] text-base'>Pssst, this is your Referral code</p>
                                                            <div className="text-[rgba(224,221,255,0.7)] text-[15px] lg:text-[15px] text-[#6b6877] font-medium flex items-center justify-center gap-2">
                                                                <a
                                                                    href={`#`}
                                                                    onClick={copyToClipboard}
                                                                    className="underline lg:no-underline lg:hover:underline lg:hover:cursor-pointer"
                                                                >
                                                                    {`${referralURL}?ref=${referralCode}`}
                                                                </a>
                                                                <button className="lg:cursor-pointer py-2 px-1" onClick={copyToClipboard}>
                                                                    <img src={copyicon} alt="" />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </GradientBox>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
