import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WagmiConfig, createClient, configureChains, chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { RouterProvider, BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/index';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { StoreProvider } from './utils/store';
import walletConnect from './assets/walletConnect-icon 1.svg';
import injected from './assets/injected.svg';
import metamask from './assets/metamask.svg';
import { styled } from "@mui/material"

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import { SocketContextProvider } from './utils/SocketProvider';
import CreditsContextProvider from './utils/creditsContext';
import StorageContextProvider from './utils/StorageContext';
import * as Sentry from '@sentry/react';
import { sentryUrl } from './constants';
import { AudioPlayerProvider } from 'react-use-audio-player';
import buffer from 'buffer';
const { Buffer } = buffer;

Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_KEY}${sentryUrl}`,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (!window.Buffer) window.Buffer = Buffer;

const { chains, provider, webSocketProvider } = configureChains([chain.mainnet, chain.arbitrum], [publicProvider()]);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: {
        logo: metamask,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '...',
        logo: walletConnect,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#1369A2',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#46298F',
  },
}));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <WagmiConfig client={client}>
    <StoreProvider>
      <StorageContextProvider>
        <SocketContextProvider>
          <SnackbarProvider
            maxSnack={5}
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
            }}
          >
            {/* <App />
        <RouterProvider router={router} /> */}
            <Provider store={store}>
              <PersistGate persistor={persistor} loading={null}>
                <CreditsContextProvider>
                  <Router>
                    <AudioPlayerProvider>
                      <App />
                      <AppRoutes />
                    </AudioPlayerProvider>
                  </Router>
                </CreditsContextProvider>
              </PersistGate>
            </Provider>
          </SnackbarProvider>
        </SocketContextProvider>
      </StorageContextProvider>
    </StoreProvider>
  </WagmiConfig>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
