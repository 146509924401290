import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import {
  pepe_top,
  pepe_coin,
  pepe_shine,
  pepe_dollar_eyes,
  coins_stacked,
  cancel_icon_w_gradient,
  usdcCoinLogo,
  pepeCoinLogo,
  pepeShadesHatLogo,
} from '../assets';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import axios from 'axios';
import Settings from '../components/Settings/Settings';
import { history_API, pepeBetContractAddress, api, tokens } from '../constants';
import { abi } from '../utils/pepebet-abi';
import { ethers } from 'ethers';
import { getLeverage, getSelectedTokenData } from '../services/service';
import LoaderIcon from '../components/LoaderIcon';
import { useStorage } from '../utils/StorageContext';
import moment from 'moment';
import UPMobile from '../components/UPMobile';

const RECENT_PLAYS_ENDPOINT = 'https://3qgmuvwbmg.execute-api.us-west-2.amazonaws.com/Prod/history';

const ITEMS_PER_PAGE = 10;
const RecentPlays = () => {
  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const [recentPlays, setRecentPlays] = useState([]);
  // const [first10Plays, setFirst10Plays] = useState()
  const [loading, setLoading] = useState(false);
  const [numPlaysToShow, setNumPlaysToShow] = useState(ITEMS_PER_PAGE);
  const navigate = useNavigate();
  const { address: useraddress } = useAccount();
  const { betCurrency } = useStorage()
  const [loadMore, setLoadMore] = useState(false)
  const [buttonIsVisible, setButtonIsVisible] = useState(false)

   // Method to scroll to the top
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling animation
    });
  }
  // setFirst10Plays(recentPlays.slice(-10));
  const first10Plays = recentPlays.slice(0, 10);
  const provider = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
  const pepeBetContract = new ethers.Contract(getSelectedTokenData(betCurrency)?.contractAddress, abi, provider);

  const fetchRecentPlays = useCallback(async () => {
    try {
      setLoading(true);
      //const leverage = await pepeBetContract.leverage();
      const leverage = await getLeverage(pepeBetContract);
      const { data } = await axios.post(api.prod.history_API, {
        useraddress,
      });
      console.log(data.data);
      const plays = data.data;
      const percent = Math.round(leverage);
      const numberOfPlays = plays.length;
      const formattedData = [];
      let totalAccum = 0;

      let contractLeverage;
      contractLeverage = await pepeBetContract.leverage();

      for (let i = 0; i < numberOfPlays; i++) {
        if (plays[i].winlose != null) {
          const pnl = plays[i].winlose == 0 ? 0 : percent;
          if (plays[i].winlose == 0) {
            totalAccum = totalAccum - parseInt(plays[i].amount);
          } else {
            totalAccum = totalAccum + plays[i].winlose;
          }

          let token = '';

          if (plays[i].token !== null && plays[i].token !== undefined && plays[i].token !== '') {
            token = plays[i].token;
          } else {
            token = 'usdc';
          }

          contractLeverage = pnl > 0 ? contractLeverage : 10000;

          let tradeLeverage =
            contractLeverage /
            10000 /
            ((Math.abs(plays[i].openPrice - plays[i].closePrice) / plays[i].openPrice) * 100);
          if (isFinite(tradeLeverage)) {
            tradeLeverage = tradeLeverage.toFixed(3);
          } else {
            tradeLeverage = 0.0;
          }

          let autoSpin = "-"
          
          if (plays[i].autospin_id && plays[i].autospin_id != null) {
            let autoSpinValues = plays[i].autospin_id.split("_").filter(item => /^\d+$/.test(item));
            if(autoSpinValues.length){
            let autospin_round = autoSpinValues[0]
            let autospin_total_rounds = autoSpinValues[1] == undefined ? "-" : autoSpinValues[1]
            autoSpin = `${autospin_round}/ ${autospin_total_rounds}`
            }else{
              autoSpin = "-"
            }
           
          }

          const formattedPlay = {
            // date: getDate(Number(plays[i].endTime)),
            date: getDate(plays[i].endTime),
            amount: plays[i].amount,
            coin: plays[i].assetTicker,
            duration: plays[i].runTime,
            direction: plays[i].direction,
            points: calculatePoints(Number(plays[i].amount), plays[i].winlose),
            token,
            pnl,
            leverage: tradeLeverage,
            total: totalAccum,
            index: plays[i].id,
            autoSpin: autoSpin
          };
          formattedData.push(formattedPlay);
        }
      }
      setRecentPlays(formattedData.reverse());
    } catch (err) {
      // console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    scrollToTop()
    fetchRecentPlays();
  }, []);

  //Handling scroll to top button visibility toggle
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      const isScrolledDown = window.scrollY > 200; // Adjust the scroll threshold as needed

      // Update the visibility state
      setButtonIsVisible(isScrolledDown);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // setFirst10Plays(recentPlays.slice(-10));

  const getDate = (timestamp) => {
    const date = new Date(Number(timestamp));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };



  function calculatePoints(amount, winlose) {
    if (winlose === 0) {
      switch (amount) {
        case 5:
          return 2;
        case 10:
          return 6;
        case 20:
          return 16;
        case 50:
          return 50;
        case 75:
          return 90;
        case 100:
          return 160;
        default:
          return 0;
      }
    } else {
      switch (amount) {
        case 5:
          return 1;
        case 10:
          return 3;
        case 20:
          return 8;
        case 50:
          return 25;
        case 75:
          return 45;
        case 100:
          return 80;
        default:
          return 0;
      }
    }
  }


  const displayResults = (betID) => {
    navigate('/result', { state: { betid: betID, prevlocation: 'history' } });
  };

  return (
    <div
      className="min-h-screen overflow-y-hidden sm:overflow-y-hidden overflow-x-hidden"
      style={{
        background: isSmallScreen ? '#09031D' : 'linear-gradient(#09031D, #160B41)',
      }}
    >
      <div className="flex justify-center relative z-10">
        <img src={pepe_top} className="w-[330px] h-auto pointer-events-none select-none" alt="pepe logo" />
        {/* <img
          src={pepe_shine}
          className="sm:hidden w-full h-[208px] absolute top-0 left-0 -z-10 pointer-events-none select-none"
          alt="pepe logo"
        /> */}
      </div>
      <div className=" w-full sm:w-3/4 xl:w-3.1/5 sm:mx-auto relative -top-[85px] text-white">
        <div className="flex sm:hidden justify-end mb-[10px] mr-4 relative z-10">
          <button className="w-6 h-6" onClick={() => navigate('/slot')}>
            <img src={cancel_icon_w_gradient} alt="cancelIcon" />
          </button>
        </div>
        <div
          className="relative sm:rounded-lg"
          style={{
            background: 'linear-gradient(78.54deg, rgba(0, 183, 240, 0.2) -21.37%, rgba(72, 25, 190, 0.2) 39.36%)',
          }}
        >
          <div className="hidden sm:flex justify-end mb-[10px] mr-4 absolute top-3 right-0">
            <button onClick={() => navigate('/slot')} className="cursor cursor-pointer w-6 h-6 z-10">
              <img src={cancel_icon_w_gradient} alt="cancelIcon" />
            </button>
          </div>
          <div className="px-[40px] sm:px-[72px]">
            <div className="sm:rounded-lg py-4">
              <div className="font-medium text-[35px] text-[#E0DDFF] mb-[23px] sm:mb-4 leading-[33px] mx-auto text-center mt-6 font-['Tourney']">
                Your History
              </div>
              <div className="overflow-x-scroll sm:overflow-x-auto">
                <table className="w-full text-[16px] text-left sm:text-center manrope font-medium">
                  <tr className="uppercase text-[14px]  text-[#83828C] tracking-[0.06em]">
                    <th className="pr-8 sm:pr-0 pb-2">date</th>
                    <th className="pr-8 sm:pr-0 pb-2">bet</th>
                    <th className="pr-8 sm:pr-0 pb-2">coin</th>
                    <th className="pr-8 sm:pr-0 pb-2">autospin</th>
                    <th className="pr-8 sm:pr-0 pb-2">time</th>
                    <th className="pr-8 sm:pr-0 pb-2">l/s</th>
                    <th className="pr-8 sm:pr-0 pb-2">Leverage</th>
                    <th className="pr-8 sm:pr-0 pb-2">Return</th>
                    <th className="pr-8 sm:pr-0 text-center pt-[19px] pb-2 gradient-text-d whitespace-pre-line">Degen {"\n"} Points</th>
                    {/* <th className="pr-8 sm:pr-0 pb-2">total</th> */}
                  </tr>
                  {loadMore ? (recentPlays
                    // .reverse()
                    // .slice(0, numPlaysToShow)
                    .map(({ date, amount, coin, duration, direction, pnl, total, index, leverage, points, token, autoSpin }, idx) => {
                      return (
                        <tr key={idx} onClick={() => displayResults(index)} className='cursor-pointer  border-b-[1px] border-white border-opacity-20 hover:bg-white hover:bg-opacity-20 transition duration-300 ease-in-out
                        '>
                          <td className="pt-6 pl-2 pr-6 lg:pl-0 lg:pr-0 uppercase"> {date} </td>
                          <td className="pt-6 sm:px-6 ">

                            <p className='mx-auto whitespace-nowrap'>
                              <img
                                src={getSelectedTokenData(token.toUpperCase())?.icon}
                                className="inline-block mr-1 mb-[4px]"
                              />
                              <span>{amount}</span>
                            </p>
                          </td>
                          <td className="pt-6 px-6 lg:px-0 uppercase"> {coin} </td>
                          <td className="pt-6 px-6 lg:px-0  uppercase whitespace-nowrap"> {autoSpin} </td>
                          <td className="pt-6 px-6 lg:px-0 "> {duration}s </td>
                          <td className="pt-6 px-6 lg:px-0  capitalize"> {direction} </td>
                          <td className="pt-6 px-6 lg:px-0  capitalize"> {leverage}x </td>
                          <td className={`pt-6 pr-2 pl-6  ${pnl > 0 ? 'text-[#18ADFA]' : 'text-[#9747FF]'}`}>{pnl}%</td>
                          <td className={`pt-6 px-6 lg:px-0  uppercase ${pnl > 0 ? '' : 'gradient-text-d font-extrabold'}`}> +{points} </td>
                          {/* <td className="pb-4">
                            {total ? (total < 0 ? '-$' : '$') + Math.abs(total).toLocaleString() : '$0'}
                          </td> */}
                        </tr>
                      );
                    })) : (
                    first10Plays
                      // .reverse()
                      // .slice(0, numPlaysToShow)
                      .map(({ date, amount, coin, duration, direction, pnl, total, index, leverage, points, token, autoSpin }, idx) => {
                        return (
                          <tr key={idx} onClick={() => displayResults(index)} className='cursor-pointer  border-b-[1px] border-white border-opacity-20 hover:bg-white hover:bg-opacity-20 transition duration-300 ease-in-out

                        '>
                            <td className="pt-6 pl-2 pr-6 lg:pl-0 lg:pr-0 uppercase"> {date} </td>
                            <td className="pt-6 px-6 lg:px-0 ">

                              <p className='mx-auto whitespace-nowrap'>
                                <img
                                  src={getSelectedTokenData(token.toUpperCase())?.icon}
                                  className='inline-block mr-1 mb-[4px]' />
                                <span>{amount}</span>
                              </p>
                            </td>
                            <td className="pt-6 px-6 lg:px-0 uppercase"> {coin} </td>
                            <td className="pt-6 px-6 lg:px-0  uppercase whitespace-nowrap"> {autoSpin} </td>
                            <td className="pt-6 px-6 lg:px-0 "> {duration}s </td>
                            <td className="pt-6 px-6 lg:px-0  capitalize"> {direction} </td>
                            <td className="pt-6 px-6 lg:px-0  capitalize"> {leverage}x </td>
                            <td className={`pt-6 pr-2 pl-6  ${pnl > 0 ? 'text-[#18ADFA]' : 'text-[#9747FF]'}`}>{pnl}%</td>
                            <td  className={`pt-6 px-6 lg:px-0  uppercase ${pnl > 0 ? '' : 'gradient-text-d font-extrabold'}`}> +{points} </td>
                            {/* <td className="pb-4">
                            {total ? (total < 0 ? '-$' : '$') + Math.abs(total).toLocaleString() : '$0'}
                          </td> */}
                          </tr>
                        );
                      })
                  )}
                </table>

                {loading ? (
                  <div className="flex justify-center h-12 items-center">
                    <div role="status" className="pt-1">
                      <LoaderIcon />
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>

              {!loadMore ? (<div onClick={() => setLoadMore(true)} className='cursor-pointer w-[120px] flex justify-center items-center my-10 px-4 py-2 hover:bg-white hover:bg-opacity-20 rounded-md duration-200 transition ease-in mx-auto'>load more</div>) :
                (<div onClick={() => setLoadMore(false)} className='cursor-pointer w-[120px] flex justify-center items-center my-10 px-4 py-2 hover:bg-white hover:bg-opacity-20 rounded-md duration-200 transition ease-in mx-auto'>see less</div>)}
              {/* {numPlaysToShow < recentPlays.length && (
                <button
                  className="block mx-auto mt-[40px] text-[13px] text-[#E0DDFF] font-medium"
                  onClick={() => {
                    if (numPlaysToShow + ITEMS_PER_PAGE > recentPlays.length) {
                      setNumPlaysToShow(recentPlays.length);
                    } else {
                      setNumPlaysToShow((prevVal) => prevVal + ITEMS_PER_PAGE);
                    }
                  }}
                >
                  {loading ? 'Loading...' : 'Load more'}
                </button>
              )} */}
            </div>
          </div>
        </div>

        <div className="absolute bottom-[-100px] right-5 p-4 w-[90%] mx-auto  ">
        <div className='flex justify-end'>
          {/* <button onClick={scrollToTop} className="uppercase w-[58.96px] h-[58.96px] rounded-full flex items-center justify-center bg-[rgba(217, 217, 217, 0.1)] border-2 border-solid border-[rgba(10,130,182,0.7)] up-btn">
            Up
          </button> */}
          <UPMobile />
        </div>
      </div>

        {/* <div className="absolute bottom-[-100px] right-5 p-4 w-[90%] mx-auto sm:hidden ">
        <div className='flex justify-end'>
          <button onClick={scrollToTop} className="uppercase w-[58.96px] h-[58.96px] rounded-full flex items-center justify-center bg-[rgba(217, 217, 217, 0.1)] border-2 border-solid border-[rgba(10,130,182,0.7)] up-btn">
            Up
          </button>
          <UPMobile />
        </div>
      </div> */}
      </div>

      

      <div className="w-full flex justify-between relative mt-14 sm:mt-0">
        <img
          src={pepeShadesHatLogo}
          className="block relative sm:fixed bottom-12 sm:bottom-0 -left-12 sm:left-0 max-w-[70%] sm:max-w-[20%] h-auto z-10"
          alt="pepe wearing dollar shades"
        />
        {/* <img
          src={coins_stacked}
          className="block absolute sm:fixed bottom-0 right-0 max-w-[50%] sm:max-w-[20%] lg:max-w-[25%] h-auto z-10"
          alt="stack of coins"
        /> */}

       
      </div>
      <Settings classes="hidden" />
    </div>
  );
};

export default RecentPlays;
