import React, { useState, useContext, useMemo, useEffect } from 'react';
import { checkMetamaskSession } from './utils/inits';
import './App.css';
import ConnectWallet from './components/modal/connectWallet';
import ConnectSlot from './components/modal/connectSlot';
import { mainback_1, top_right, state, bottom } from './assets';
import { observer } from 'mobx-react-lite';
import { useNetwork } from 'wagmi';

import { StoreContext } from './utils/store';
import { SummarizePopup } from './components/SummarizePopup/SummarizePopup';
import WrongNetwork from './components/modal/WrongNetwork';
import { useMediaQuery } from 'usehooks-ts';
import NoAccessMobileModal from './components/NoAccessMobileAlert/NoAccessMobileModal';
import DepositWithdrawFunds from './components/DepositWithdrawFunds/DepositWithdrawFunds';
import AutoSpin from './components/AutoSpin/AutoSpin';
import { SideMenu } from './components/SideMenu';
import ImageModal from './components/ImageModal';
import AutoSpinResult from './components/modal/AutoSpinResult';
import StakeModal from './components/modal/StakeModal';
import DegenStakeModal from './components/modal/DegenStakeModal';
import LockModal from './components/modal/LockModal';
import LockPPLPModal from './components/modal/LockPPLPModal';
import ClaimModal from './components/modal/ClaimModal';
import { useLocation } from 'react-router-dom';
import ClaimConfirmation from './components/modal/ClaimConfirmation';
import WalletMenuPopup from './components/WalletMenuPopup';
import ReferralModal from './components/modal/referralModal';
import * as Sentry from '@sentry/react';
import QuestsModal from './components/QuestsModal';
import ClaimLockModal from './components/modal/ClaimLockModal';
import DisclaimerModal from './components/modal/DisclaimerModal';
import AudioPlayer from './components/Audio/AudioPlayer';
import { useStorage } from './utils/StorageContext';
import { useAutoSpinTradeService } from '../src/hooks/useAutoSpinTradeService';
import MissingFunds from './components/modal/MissingFunds';

function App() {
  const store = useContext(StoreContext);
  const { checkBetAutoSpinBetStatus, stopInterval } = useAutoSpinTradeService();
  const { autoSpinData } = useStorage();
  const { chain } = useNetwork();
  const {
    loading,
    connectmodal,
    setConnectModal,
    slotmodal,
    setSlotModal,
    goodluckModal,
    setGoodLuckModal,
    showDepositWithdrawModal,
    setDepositWithdrawModal,
    showSideMenu,
    setSideMenu,
    showHowToPlay,
    setShowHowToPlay,
    stakeModal,
    setStakeModal,
    lockModal,
    lockPPLPModal,
    setLockModal,
    setLockPPLPModal,
    claimModal,
    setClaimModal,
    promptType,
    claimConfirmModal,
    setClaimConfirmModal,
    showWalletMenuPopup,
    setWalletMenuPopup,
    isQuestsOpen,
    setQuestsModal,
    setReferralModal,
    referralModal,
    degenStakeModal,
    setDegenStakeModal,
    currentStakeTab,
    isEsPeg,
    esPegStakeID,
    esPegLockID,
    claimLockModal,
    setClaimLockModal,
    disclaimerModal,
    setDisclaimerModal,
    showAutoSpinResult
  } = store;
  const closeGoodLuckModal = useMemo(() => setGoodLuckModal(false), []);
  const [openModal, setOpenModal] = useState(true);
  const [noAccessMobileModal, setNoAccessMobileModal] = useState(false);

  const isMobileDevice = useMediaQuery('(max-width: 640px)');
  const location = useLocation();
  React.useEffect(() => {
    let stake_lock = location.pathname === '/stake' || location.pathname === '/lock';
    if (isMobileDevice && stake_lock) {
      setNoAccessMobileModal(true);
    } else {
      setNoAccessMobileModal(false);
    }
  }, [isMobileDevice]);


  React.useEffect(() => {
    if (autoSpinData.isAuto) {
      if (location.pathname !== '/chart') {
        console.log('current autospin data from APP JS::', autoSpinData)
        checkBetAutoSpinBetStatus(autoSpinData)
      }
    }

    return () => stopInterval()
  }, [location.pathname, autoSpinData])

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      if (connectmodal) {
        setConnectModal(false);
        return;
      }
      if (showDepositWithdrawModal) {
        setDepositWithdrawModal(false);
        return;
      }
      if (showSideMenu) {
        setSideMenu(false);
        return;
      }
    }
  };

  useEffect(() => {
    checkMetamaskSession();
  }, [loading]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);

    return () => {
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [connectmodal, showDepositWithdrawModal, showSideMenu]);

  //Method to close depositwithdrawFunds Modal
  const closeDepositWithdrawFundsModal = () => {
    // setWalletMenuPopup(true); //open wallet menu popup
    setDepositWithdrawModal(false);
  };

  const closeMissing = () => {
    console.log('close missing');
  }

  return (
    <>
      <MissingFunds isOpen={true} close={closeMissing} />
      <ReferralModal open={setReferralModal} isOpen={referralModal} />
      <ConnectWallet open={setConnectModal} isOpen={connectmodal} />
      <ConnectSlot open={setSlotModal} isOpen={slotmodal} />
      {goodluckModal && <SummarizePopup closePopup={closeGoodLuckModal} />}
      {chain && chain.id !== 42161 && <WrongNetwork isOpen={openModal} open={setOpenModal} />}
      <DepositWithdrawFunds isOpen={showDepositWithdrawModal} close={closeDepositWithdrawFundsModal} />
      {showSideMenu && (
        <span onMouseEnter={() => setSideMenu(true)} onMouseLeave={() => setSideMenu(false)}>
          <SideMenu />
        </span>
      )}
      {/* {noAccessMobileModal && <NoAccessMobileModal />} */}

      {showAutoSpinResult && (
        <AutoSpinResult isOpen={showAutoSpinResult} numOfRounds={autoSpinData.rounds} />
      )}

      <ImageModal isOpen={showHowToPlay} setOpen={setShowHowToPlay} />
      <StakeModal
        open={setStakeModal}
        isOpen={stakeModal}
        currentTab={currentStakeTab}
        isEsPeg={isEsPeg}
        esPegStakeID={esPegStakeID}
      />
      <LockModal open={setLockModal} isOpen={lockModal} promptType={promptType} isEsPeg={isEsPeg} />
      <LockPPLPModal open={setLockPPLPModal} isOpen={lockPPLPModal} promptType={promptType} />
      <ClaimLockModal open={setClaimLockModal} isOpen={claimLockModal} esPegLockID={esPegLockID} isEsPeg={isEsPeg} />
      <DegenStakeModal open={setDegenStakeModal} isOpen={degenStakeModal} />

      <ClaimModal open={setClaimModal} />
      {claimConfirmModal && <ClaimConfirmation isOpen={claimConfirmModal} open={setClaimConfirmModal} />}
      <WalletMenuPopup isOpen={showWalletMenuPopup} />
      <QuestsModal isOpen={isQuestsOpen} setOpen={setQuestsModal} />
      <DisclaimerModal isOpen={disclaimerModal} open={setDisclaimerModal} />
      <AudioPlayer />
    </>
  );
}

export default Sentry.withProfiler(observer(App));
// export default observer(App);
