import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { cancel_icon_w_gradient, pepeFlex } from '../../assets';

const ClaimConfirmation = ({ isOpen, open }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[rgba(0,0,0,0.75)] flex">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="transition-all transform w-[754px] autospin-bg flex flex-col max-[640px]:w-[90%] min-w-[375px] h-[552px] max-[640px]:h-[406.67px]">
              {/* main container */}
              <div className="w-full h-full flex justify-center">
                <div className="flex flex-col w-[90%] sm:w-[694px] h-full">
                  <div className="flex justify-end w-full h-[15px] pt-[25px]">
                    <button className="cursor cursor-pointer w-[15px] h-full">
                      <img src={cancel_icon_w_gradient} alt="cancelIcon" onClick={() => open(false)} />
                    </button>
                  </div>

                  {/* main content area */}
                  <div className="flex flex-col justify-center w-full mt-[25px]">
                    <span className="font-[Tourney] font-semibold gradient-text-b text-center text-[33px] sm:text-[70px] leading-[100%] sm:leading-[160%]">
                      YEAH!
                    </span>

                    <span className="barlow text-[#7f75ee] sm:text-white font-semibold sm:font-normal text-[17px] sm:text-[30px] leading-[20.4px] sm:leading-[48px] text-center mt-[25px] sm:mt-[30px]">
                      You have successfully claimed
                    </span>

                    <span className="font-[Tourney] mt-[10px] sm:mt-10 font-bold sm:font-semibold gradient-text-b text-[28px] sm:text-[43px] text-center leading-[110%] sm:leading-[160%] tracking-[0.03em] sm:tracking-normal">
                      231.29 PEG
                    </span>

                    <div className="flex justify-center items-end mt-[25px] sm:mt-5">
                      <span className="text-center underline barlow font-normal text-[11px] sm:text-xs text-[#E0DDFF] sm:text-white leading-[13.2px] sm:leading-[160%] cursor-pointer">
                        See tx
                      </span>
                    </div>

                   
                    <img src={pepeFlex} className="flex absolute bottom-1 left-10 sm:left-[30px]" alt="stylish pepe image" />
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ClaimConfirmation;
