import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  black_coin_crown,
  finalmobilelogo,
  gold_coin_crown,
  pepe_top,
  pill,
  pill_pack,
  pills,
  pills_split,
} from '../assets';
import { cancel_icon_w_gradient } from '../assets';
import { useNavigate } from 'react-router-dom';
import { CreditsContext } from '../utils/creditsContext';
import '../styles/quests.css';
import UPMobile from './UPMobile';

export default function QuestsModal({ isOpen, setOpen }) {
  const navigate = useNavigate();

  const creditsContext = useContext(CreditsContext);
  const { completedStreakForToday, questStreakCompleted, chipsAndDays, hasLostStreak } = creditsContext;

  const [showIntroductionMesage, setShowIntroductionMessage] = useState(false);

  const [introMessageDisplay, setIntroMessageDisplay] = useState(false);

  const handleStreakDayClick = () => {
    navigate('/slot');
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    if (showIntroductionMesage) {
      setShowIntroductionMessage(false);
      setIntroMessageDisplay(false);
    }
  };

  // Method to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling animation
    });
  };

  // const handleIntroMessageDisplay = () => {
  //   setIntroMessageDisplay(!introMessageDisplay)
  //   setShowIntroductionMessage(true)
  //   window.scroll(0,0)
  // }

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  };

  useEffect(() => {
    setIntroMessageDisplay(introMessageDisplay);
  }, [introMessageDisplay]);

  // variations of this message -- introduction | lost | congrats
  const innerContentToShow = showIntroductionMesage
    ? 'introduction'
    : completedStreakForToday
      ? 'congrats'
      : hasLostStreak
        ? 'lost'
        : 'introduction';

  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'auto' }}
      >
        <Dialog as="div" className="relative z-[1000]" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" style={{ pointerEvents: 'none' }} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="shadow-xl transition-all relative w-screen">
                  <div className="text-white min-h-screen bg-[#09031D] sm:bg-transparent pb-16 lg:pb-20">
                    <span id="top"> </span>
                    <img src={finalmobilelogo} alt="" className="hidden pointer-events-none block mx-auto sm:hidden" />
                    <div className="sm:pt-32 barlow">
                      <div className="relative w-[90%] mx-auto">
                        <div className="relative rounded-2xl border-[1px] border-solid border-[rgba(10,130,182,0.7)] sm:border-none bg-[#09031D] sm:shadow-[0px_0px_20px_rgba(24,173,250,0.8),_0px_0px_6px_#00B7F0]">
                          <div className="pt-[1.875rem] pb-[6.5rem] px-4 sm:p-4 sm:pb-8 lg:pb-20">
                            <button className="absolute top-4 right-4 hidden sm:block" onClick={handleClose}>
                              <img src={cancel_icon_w_gradient} alt="cancel" className="w-[1.625rem] h-auto" />
                            </button>

                            <div className="flex justify-end sm:hidden">
                              <button
                                className="w-6 flex justify-center items-center absolute top-4 right-4 md-2"
                                onClick={handleClose}
                              >
                                <img alt="" src={cancel_icon_w_gradient} className="" />
                              </button>
                            </div>

                            <img
                              src={pepe_top}
                              alt=""
                              className="pointer-events-none hidden mx-auto sm:block w-[25rem] absolute top-[-9rem] left-0 right-0"
                            />

                            <h1 className='font-["Tourney"] gradient-text font-bold text-[1.75rem] pt-3 sm:text-[3.125rem] sm:pt-[5rem] mb-2 text-center'>
                              PEPE&apos;S PILLS
                            </h1>

                            <div className="w-full lg:w-[46.875rem] mx-auto border-[1px] border-solid border-[#1f789e] pl-2 pr-4 py-5 sm:p-5 text-center rounded-lg overflow-x-hidden mb-8 sm:mb-12 days-container-shadow">
                              <h3 className='text-center font-["Tourney"] font-semibold text-[24px] leading-[100%] mb-[0.938rem] sm:mb-5'>
                                YOUR DAILY DOSE
                              </h3>
                              <div className="relative z-10">
                                <div className="contents lg:flex lg:gap-5">
                                  <div className="relative flex gap-2 mb-3 sm:gap-5 sm:mb-5 lg:mb-0 lg:contents gradient-connecting-line">
                                    {chipsAndDays.slice(0, 4).map(({ day, chips }) => (
                                      <button
                                        onClick={() => {
                                          if (!completedStreakForToday && questStreakCompleted + 1 === day) {
                                            handleStreakDayClick();
                                          }
                                        }}
                                        key={day}
                                        className={`flex-1 py-[0.313rem] sm:pt-[0.313rem] sm:pb-[8px] justify-center items-center flex flex-col gap-[0.313rem] text-xs rounded-md
                                         ${day === questStreakCompleted || questStreakCompleted > day
                                            ? 'quest-day-container-active'
                                            : !completedStreakForToday && questStreakCompleted + 1 === day
                                              ? 'quest-day-pending'
                                              : questStreakCompleted < day
                                                ? 'quest-day-container-upcoming'
                                                : 'quest-day-container-past'
                                          }`}
                                      >
                                        <div className={`contents ${'default-content'}`}>
                                          <span className="font-medium text-[10px] sm:text-[12px] leading-[12px] sm:leading-[14.4px]">
                                            DAY {day}
                                          </span>
                                          <div className="flex gap-[2.5px] sm:gap-[6px] items-center">
                                            <span className="font-bold text-[17px] leading-[100%]">+{chips}</span>
                                            <img src={pill} alt="" className="w-5 h-auto" />
                                          </div>
                                        </div>

                                        <div className="contents hover-content">
                                          {day === questStreakCompleted ? (
                                            <span className="text-[12px] font-medium leading-[14px]">
                                              Already
                                              <br />
                                              collected
                                            </span>
                                          ) : !completedStreakForToday && questStreakCompleted + 1 === day ? (
                                            <span className="text-[12px] leading-[14px] font-medium py-[5.7px]">
                                              Spin to
                                              <br /> collect
                                            </span>
                                          ) : questStreakCompleted > day ? (
                                            <span className="text-[12px] font-medium leading-[14px]">
                                              Already
                                              <br />
                                              collected
                                            </span>
                                          ) : (
                                            <span className="text-[12px] leading-[14px] px-[10px]">
                                              Not eligible
                                              <br />
                                              yet
                                            </span>
                                          )}
                                        </div>
                                      </button>
                                    ))}
                                  </div>

                                  <div className="relative flex justify-center w-3/4 mx-auto gap-2 sm:gap-5 lg:contents gradient-connecting-line line-extend-left">
                                    {chipsAndDays.slice(4).map(({ day, chips }) => (
                                      <button
                                        onClick={() => {
                                          if (!completedStreakForToday && questStreakCompleted + 1 === day) {
                                            handleStreakDayClick();
                                          }
                                        }}
                                        key={day}
                                        className={`flex-1 py-[0.313rem] sm:pt-[0.313rem] sm:pb-[8px] justify-center items-center flex flex-col gap-[0.313rem] text-xs rounded-md
                                         ${day === questStreakCompleted || questStreakCompleted > day
                                            ? 'quest-day-container-active'
                                            : !completedStreakForToday && questStreakCompleted + 1 === day
                                              ? 'quest-day-pending'
                                              : questStreakCompleted < day
                                                ? 'quest-day-container-upcoming'
                                                : 'quest-day-container-past'
                                          }`}
                                      >
                                        <div className={`contents ${'default-content'}`}>
                                          <span className="font-medium text-[10px] sm:text-[12px] leading-[12px] sm:leading-[14.4px]">
                                            DAY {day}
                                          </span>
                                          <div className="flex gap-[2.5px] sm:gap-[6px] items-center">
                                            <span className="font-bold text-[17px] leading-[100%]">+{chips}</span>
                                            <img src={pill} alt="" className="w-5 h-auto" />
                                          </div>
                                        </div>

                                        <div className="contents hover-content">
                                          {day === questStreakCompleted ? (
                                            <span className="text-[12px] font-medium leading-[14px]">
                                              Already
                                              <br />
                                              collected
                                            </span>
                                          ) : !completedStreakForToday && questStreakCompleted + 1 === day ? (
                                            <span className="text-[12px] leading-[14px] font-medium py-[5.7px]">
                                              Spin to
                                              <br /> collect
                                            </span>
                                          ) : questStreakCompleted > day ? (
                                            <span className="text-[12px] font-medium leading-[14px]">
                                              Already
                                              <br />
                                              collected
                                            </span>
                                          ) : (
                                            <span className="text-[12px] leading-[14px] px-[10px]">
                                              Not eligible
                                              <br />
                                              yet
                                            </span>
                                          )}
                                        </div>
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <p className="text-center barlow font-medium text-[17px] leading-[23.8px] gradient-text mt-4 sm:mt-5">
                                Spin daily to accumulate Pepe&apos;s Pills <br className="sm:hidden" />
                                {/* <span className="font-bold">special pill dose</span> from Pepe */}
                              </p>
                            </div>

                            <div className="w-full sm:mx-auto sm:w-4/5 max-w-[50rem] sm:bg-[rgba(255,255,255,0.05)] sm:rounded-2xl sm:p-[4.375rem]">
                              <div className="relative bg-[rgba(255,255,255,0.05)] border-[0.5px] border-solid border-[#18ADFA] shadow-[0px_0px_14px_#00B7F0] rounded-2xl mt-[4.375rem] sm:mt-0">
                                {/* state 1 -- introduction */}
                                <div
                                  className={`pt-[4.688rem] pb-10 px-5 sm:px-12 lg:px-28 ${innerContentToShow !== 'introduction' ? 'hidden' : ''
                                    }`}
                                >
                                  <img
                                    src={pills_split}
                                    alt="pepe's capsule pill split open with contents pouring out"
                                    className="absolute top-0 -translate-y-7 left-0 right-0 mx-auto w-[6.639rem] pointer-events-none"
                                  />

                                  <h5 className="font-bold text-[24px] leading-[33.6px] text-center mb-[30px]">
                                    Spin daily and receive Pepe&apos;s Pills
                                  </h5>
                                  <div className="barlow sm:text-[17px] sm:leading-[28.9px]">
                                    <p className="mb-8">
                                      You'll be able to exchange Pepe's Pills for some truly "high" rewards. As your
                                      daily streak grows, so does your tolerance - which is why he'll start giving you
                                      more. And more.
                                    </p>
                                    <p className="mb-8">
                                      But beware - miss a day, and your tolerance resets, sending you back to pill one.{' '}
                                    </p>
                                    <p className="font-bold">Ready to get your first dose of Pepe's Pills?</p>
                                  </div>

                                  <button
                                    onClick={() => {
                                      navigate('/slot');
                                      handleClose();
                                    }}
                                    className="block mx-auto py-[14px] px-[30px] sm:min-w-[216px] border-solid border-[1px] border-[#18ADFA] rounded-md bg-[linear-gradient(92.64deg,_#00B7F0_9.56%,_#4819BE_127.72%)] shadow-[0px_0px_11px_#00B7F0] mt-10"
                                  >
                                    <span className="font-bold text-[18px] sm:text-[21px] leading-[100%]">
                                      Ready, let&apos;s spin!
                                    </span>
                                  </button>
                                </div>

                                {/* state 2 -- lost */}
                                <div
                                  className={`pt-[4.688rem] pb-10 px-5 sm:px-12 lg:px-28 ${innerContentToShow !== 'lost' ? 'hidden' : ''
                                    }`}
                                >
                                  <img
                                    src={pills_split}
                                    alt="pepe's capsule pill split open with contents pouring out"
                                    className="absolute top-0 -translate-y-7 left-0 right-0 mx-auto w-[6.639rem] pointer-events-none"
                                  />

                                  <h5 className="font-bold text-[24px] leading-[33.6px] text-center mb-[30px]">
                                    Lost Your Tolerance
                                  </h5>
                                  <div className="barlow sm:text-[17px] sm:leading-[28.9px]">
                                    <p>
                                      You missed a day and reset your tolerance. <br />
                                      Ready to build it back up?
                                    </p>
                                  </div>

                                  <button
                                    onClick={handleClose}
                                    className="block mx-auto py-[14px] px-[30px] sm:min-w-[216px] border-solid border-[1px] border-[#18ADFA] rounded-md bg-[linear-gradient(92.64deg,_#00B7F0_9.56%,_#4819BE_127.72%)] shadow-[0px_0px_11px_#00B7F0] mt-10"
                                  >
                                    <span className="font-bold text-[18px] sm:text-[21px] leading-[100%]">
                                      LFG, let&apos;s Spin!
                                    </span>
                                  </button>

                                  <button
                                    className="mt-10 text-[14px] leading-[23.8px] block mx-auto underline"
                                    onClick={() => setShowIntroductionMessage(true)}
                                  >
                                    What are Pepe&apos;s Pills?
                                  </button>
                                </div>

                                {/* state 3 -- congrats page */}
                                <div
                                  className={`px-5 sm:px-12 lg:px-28 pt-[10px] pb-10 ${innerContentToShow !== 'congrats' ? 'hidden' : ''
                                    }`}
                                >
                                  <img
                                    src={pill_pack}
                                    alt="pack of pepe's pills"
                                    className="absolute top-full sm:top-0 left-0 right-0 mx-auto pointer-events-none w-[8.188rem] sm:w-[10.688rem] -translate-y-[2.5rem] sm:-translate-y-[3.938rem] sm:translate-x-1/2"
                                  />

                                  <div className="flex gap-[1.063rem] sm:gap-0 justify-center items-center">
                                    <p className="font-bold text-[4rem] leading-[5.6rem] mb-[0.625rem] sm:-translate-x-[calc(10.688rem_/_4)]">
                                      +
                                      {
                                        chipsAndDays
                                          .filter((item) => item.day === questStreakCompleted)
                                          .map((item) => item.chips)[0]
                                      }
                                      {/* {questStreakCompleted > 7
                                        ? chipsAndDays[0].chips
                                        : questStreakCompleted > 0
                                          ? chipsAndDays[questStreakCompleted - 1].chips
                                          : ''} */}
                                    </p>
                                    <img src={pills} alt="" className="sm:hidden" />
                                  </div>
                                  <h5 className="font-bold text-[24px] leading-[33.6px] text-center mb-[30px]">
                                    Congrats,
                                    <br />
                                    you acquired your daily dose!
                                  </h5>
                                  <div className="barlow sm:text-[17px] sm:leading-[28.9px] mb-10">
                                    <p>Come back tomorrow to get your next dose of Pepe's Pills.</p>
                                  </div>

                                  <button
                                    onClick={() => {
                                      navigate('/slot');
                                      handleClose();
                                    }}
                                    className="block mx-auto py-[14px] px-[30px] sm:min-w-[216px] border-solid border-[1px] border-[#18ADFA] rounded-md bg-[linear-gradient(92.64deg,_#00B7F0_9.56%,_#4819BE_127.72%)] shadow-[0px_0px_11px_#00B7F0]"
                                  >
                                    <span className="font-bold text-[18px] sm:text-[21px] leading-[100%]">
                                      See you Tomorrow
                                    </span>
                                  </button>

                                  <button
                                    className="mt-10 text-[14px] leading-[23.8px] block mx-auto underline"
                                    onClick={() => setShowIntroductionMessage(true)}
                                  >
                                    What are Pepe&apos;s Pills?
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" p-4 w-[90%] mx-auto sm:hidden">
                            <div className='flex justify-end' >
                              <UPMobile />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
