import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext } from 'react';
import { cancel_icon_w_gradient, smartPepe } from '../../assets';
import { StoreContext } from '../../utils/store';
import { ethers } from 'ethers';

const ClaimModal = ({ open }) => {
  const Store = useContext(StoreContext);
  const { claimTx, claimingAmounts, claimModal, isEsPeg } = Store;
  console.log('Claiming amount: ', claimingAmounts, typeof claimingAmounts);
  return (
    <>
      <Transition appear show={claimModal} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={() => open(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-deep-blue/[0.89] flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`modal-dialog transition-all transform w-[739px] h-[600px] stake-modal flex max-[480px]:w-4/5 relative`}
              >
                <div className="flex flex-col justify-around w-full mx-auto">
                  <div className="flex items-end justify-end mt-4 mr-4">
                    <button onClick={() => open(false)}>
                      <img src={cancel_icon_w_gradient} alt="cancel icon" width="15px" />
                    </button>
                  </div>
                  <>
                    <div className='flex items-center justify-center text-4xl font-["Tourney"] font-extrabold gradient-text text-center'>
                      YEAH!
                    </div>
                    <div className="mb-40 text-center text-white barlow">
                      <p className="pb-2 text-xl">You have successfully claimed</p>
                      <p className=' text-4xl font-["Tourney"] font-extrabold gradient-text py-2'>
                        {typeof claimingAmounts === 'object' ? parseFloat(ethers.utils.formatUnits(claimingAmounts, 18)).toFixed(4) : claimingAmounts}
                        <span className='ml-[10px]'>PEG</span>
                      </p>
                      <p className="text-xl">of rewards!</p>
                      {/* <a className="text-xs underline" target="__blank" href={'https://arbiscan.io/tx/' + claimTx}>
                        See tx
                      </a> */}
                    </div>
                    <img src={smartPepe} className="absolute bottom-0 left-0" />
                  </>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ClaimModal;
