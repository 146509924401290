import { Dialog, Transition } from '@headlessui/react';
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Fragment } from 'react';
import {
  cancel_icon_w_gradient,
  copyicon,
  gold_coin_crown,
  pepeShadesHatLogo,
  pepe_trump_hat,
  pepe_wearing_crown,
  pill,
  usdcCoinLogo,
  y2k,
  blueToken,
} from '../assets';
import { useSnackbar } from 'notistack';
import { StoreContext } from '../utils/store';
import { useAccount, useSigner } from 'wagmi';
import { useHref, useNavigate } from 'react-router-dom';
import { api } from '../constants';
import { CreditsContext } from '../utils/creditsContext';
import axios from 'axios';
import QuestIcon from './QuestIcon';
import NewQuestStateIcon from './NewQuestStateIcon';
import Wallet from './Wallet';
import UPMobile from './UPMobile';

const WalletMenuPopup = ({ isOpen }) => {
  const store = useContext(StoreContext);
  const creditsStore = useContext(CreditsContext);
  const { questChips, questStreakCompleted, completedStreakForToday } = creditsStore;
  const {
    setDepositWithdrawModal,
    setWalletMenuPopup,
    referralCode: refCode,
    setDepositWithdrawToken,
    setStoreState,
    setDepositWithdrawOperation,
    questDaysSpun,
    setQuestsModal,
  } = store;
  const [referralCode, setReferralCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const [points, setPoints] = useState(0)

  const getReferralCode = useCallback(async () => {
    if (address) {
      try {
        const response = await axios.get(`${api.prod.prod_API}/refercode?address=${address}`);
        const data = response.data.refcode[0]; //unsure of returned data type, assuming json
        setStoreState('referralCode', data.refcode);
        setReferralCode(data.refcode);
        return data;
      } catch (e) {
        console.error('Failed to check for referralCode: ', e);
        throw new Error('Failed to check for referralCode');
      }
    }
  }, [address]);

  const openDepositWithdrawal = (coin, operation) => {
    setDepositWithdrawToken(coin);
    setDepositWithdrawOperation(operation);
    setWalletMenuPopup(false);
    setDepositWithdrawModal(true);
  };

  useEffect(() => {
    if (!refCode) {
      getReferralCode();
    }
  }, [questChips, questDaysSpun, address, getReferralCode]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin + '?ref=' + referralCode}`);
      enqueueSnackbar('Referral link copied.', {
        variant: 'success',
      });
    } catch (err) {
      try {
        document.querySelector('[data-element="referral-link-container"]').select();
        document.execCommand('copy');
        enqueueSnackbar('Referral link copied.', {
          variant: 'success',
        });
      } catch (err) {
        enqueueSnackbar('An error occurred while copying the referral link.', { variant: 'error' });
      }
    }
  };

  const goToQuestsPage = () => {
    setWalletMenuPopup(false);
    setQuestsModal(true);
  };

  const handleQuests = () => {
    setWalletMenuPopup(false);
    setQuestsModal(true)
  }

  const handleDOTW = () => {
    setWalletMenuPopup(false)
    navigate('/degen-dashboard')
  }

    const fetchDegenPoints = () => {
      const startCount = 0
      const endCount = 0

      return new Promise(async (res, reject) => {
        axios
          .get(`${api.prod.degen_API}?start=${startCount}&end=${endCount}`, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            // console.log({ response });
            res(response.data);
          })
          .catch((error) => {
            reject({ message: error.response.data.message });
          });
      });
    };

    const getDegenPoints = async() => {
      try {
        const {data} = await fetchDegenPoints()
        let {points} = data.find((user) => user.useraddress === address);
        setPoints(points);
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(()=>{
      getDegenPoints()
    },[address])

  return (
    <Transition
      appear
      show={isOpen}
      as={Fragment}
      style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'auto' }}
    >
      <Dialog as="div" className="relative z-[1000]" onClose={() => setWalletMenuPopup(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full sm:items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="text-white transform transition-all relative w-full">
                <div className="relative">
                  <div className="z-10 relative">
                    <div className="w-full sm:w-[43rem] mx-auto sm:my-12">
                      <div className="w-full relative sm:rounded-2xl bg-[#09031D] pt-5 px-5 pb-12 sm:px-[3.375rem] sm:pt-[1.875rem] wallet-menu-gradient-container">
                        <div className="w-full relative barlow">
                          {address ? (
                            <div className="font-medium flex justify-start gap-1 pt-2">
                              <span className="text-[1.125rem] leading-[1.125rem] text-[#fff]">Your Wallet</span>
                              <span className="text-[0.938rem] text-[#fff]/40">{address.slice(0, 6)}...</span>
                            </div>
                          ) : null}
                          <button className="absolute top-0 right-0" onClick={() => setWalletMenuPopup(false)} id="top">
                            <img src={cancel_icon_w_gradient} alt="" />
                          </button>

                          <div className={isConnected ? 'h-[35.7px]' : 'h-[63.7px]'} />

                          <div className="sm:flex sm:flex-row-reverse gap-[13%] mb-8 items-stretch">
                            <button
                              onClick={() => {
                                navigate('/degen-dashboard');
                                setWalletMenuPopup(false);
                              }}
                              className="block barlow font-semibold text-sm leading-[1.063rem] flex-1 w-[42%] sm:w-auto py-2 mr-auto sm:mx-0 min-w-[10.5rem] bg-[#F82C5D] text-white shadow-[inset_0px_4px_7px_rgba(255,143,143,0.55)] rounded-[9px] mb-5 sm:mb-0"
                            >
                              Do Not Click Here!
                            </button>

                            <button
                              onClick={goToQuestsPage}
                              className="sm:w-3/5 w-full bg-[rgba(255,255,255,0.1)] rounded-xl"
                            >
                              <div className="w-full py-2 sm:py-0">
                                <div className="flex justify-center items-center font-medium gap-[6.88px]">
                                  <span className="text-sm leading-[16.8px]">Your Daily Streak</span>
                                  <NewQuestStateIcon
                                    variant={
                                      questStreakCompleted > 0 && completedStreakForToday
                                        ? 'ongoing'
                                        : questStreakCompleted > 0
                                        ? 'warning'
                                        : 'inactive'
                                    }
                                  />
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="bg-[rgba(255,255,255,0.05)] border-solid border-[0.5px] border-[#18ADFA] rounded-2xl pt-6 pb-[20px] px-3 sm:p-5 sm:pb-[18px] quest-container-box-shadow mb-8">
                            <div className="w-full sm:flex flex-row justify-end font-medium text-xs mb-8">
                              {/* <div className="hidden sm:block max-w-[7.5rem]">
                                <button className="leading-[18px] px-[10px] py-[7px] bg-white/10 rounded-md">
                                  My Inventory
                                </button>
                              </div> */}

                              <div className="flex flex-1 justify-end">
                                <button className="basis-1/3 sm:basis-[6rem] grow-0 shrink-0 px-4 py-[5px] bg-[#09031D] rounded-[5px] btn-blueish-shadow">
                                  My Dosh
                                </button>
                                <button
                                  className="basis-1/3 sm:basis-[6rem] grow-0 shrink-0 px-4 py-[5px] text-[#5D6588] opacity-40"
                                  disabled
                                >
                                  My Items
                                </button>
                                <button
                                  className="basis-1/3 sm:basis-[6rem] grow-0 shrink-0 px-4 py-[5px] text-[#5D6588] opacity-40"
                                  disabled
                                >
                                  My NFT's
                                </button>
                              </div>
                            </div>

                            <div className="flex justify-between items-center font-medium text-white pt-[10px] sm:pt-[19px] pb-[6px] sm:pb-0 px-[20px] mb-[7px] sm:mb-5 border-t-[1px] border-solid border-[#18ADFA] rounded-lg flex-col">
                              <div className="flex items-start gap-[7px] w-full justify-between">
                                <img alt="gold coin" src={pill} className="w-4 h-auto" />
                                <div className="flex-1 relative bottom-1">
                                  <p className="flex justify-between w-full mb-[7px]">
                                    <span className="text-[13px] sm:text-[15px] leading-[20px] font-medium">
                                      Pepe&apos;s Pills
                                    </span>
                                    <span className="text-[15px] leading-[18px] cursor-pointer"
                                      onClick={handleQuests}
                                    >{questChips}</span>
                                  </p>

                                  <p className="flex justify-between w-full">
                                    <span className="text-[13px] sm:text-[15px] leading-[20px] font-medium">
                                      Degen Points
                                    </span>
                                    <span className="text-[15px] leading-[18px] cursor-pointer"
                                      onClick={handleDOTW}
                                    >{points}</span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <Wallet token={'USDC'} hasClaim={true} />
                            <Wallet token={'PEG'} hasClaim={true} hasLockStake={true} />
                            <Wallet token={'esPEG'} hasLockStake={true} />

                            {/* <Wallet token={'USDC'} />
                            <Wallet token={'PEG'} />
                            <Wallet token={'PEPE'} /> */}
                          </div>
                          <div className="bg-[rgba(255,255,255,0.05)] rounded-2xl border-[0.5px] border-solid border-[#18ADFA] p-5 sm:px-14 sm:mb-[4.5rem]">
                            <h5 className='font-["Tourney"] font-bold text-[1.438rem] leading-[100%] gradient-text text-center'>
                              BRING YOUR OWN DEGEN
                            </h5>
                            <p className="font-medium text-xs text-center leading-7 mb-4">
                              Get a gift from Pepe when you refer a friend
                            </p>

                            <div className="bg-[rgba(255,255,255,0.05)] rounded-2xl p-7">
                              <p className="font-light leading-4 text-center mb-[0.625rem] sm:mb-[0.438rem]">
                                YOUR REFERRAL LINK
                              </p>
                              <div className="flex justify-center items-center">
                                <input
                                  data-element="referral-link-container"
                                  readOnly
                                  className="bg-transparent max-w-[80%] barlow text-white font-medium text-[0.938rem] leading-[1.125rem] underline overflow-x-hidden whitespace-nowrap text-ellipsis"
                                  value={`${window.location.origin + '?ref=' + referralCode}`}
                                />
                                <button className="flex w-8 justify-center" onClick={copyToClipboard}>
                                  <img src={copyicon} alt="copy" />
                                </button>
                              </div>
                            </div>
                            <button
                              onClick={() => {
                                navigate('/referrals');
                                setWalletMenuPopup(false);
                              }}
                              className="py-1 px-[23px] block mx-auto mt-5 text-xs text-center bg-[rgba(9,3,29,0.8)] border-wallet-popup-copy-btn"
                            >
                              View Referral Page
                            </button>
                          </div>
                        </div>

                        <div className="hidden absolute left-0 bottom-0 w-36 h-fit overflow-hidden rounded-none rounded-bl-[inherit] sm:block">
                          <img
                            src={pepeShadesHatLogo}
                            className="w-36 h-auto relative -bottom-8"
                            alt="pepe wearing a hat and glasses"
                          />
                        </div>

                        <div className=" p-4 w-[90%] mx-auto sm:hidden">
                          <div className="flex justify-end">
                            <UPMobile />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WalletMenuPopup;
