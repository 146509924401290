import { Disclosure, Transition } from '@headlessui/react'
import React, { useContext } from 'react'
import up from '../assets/images/dropDownArrow.svg';
import { useStorage } from '../utils/StorageContext';
import { StoreContext } from '../utils/store';
import axios from 'axios';
import { useAccount } from 'wagmi';
import { permission_API } from '../constants';

const Disclaimer = () => {
  const Store = useContext(StoreContext)
  const {setDisclaimerModal} = Store
  const { address } = useAccount()

  const agreement = async() => {
    try{
      await axios.post(permission_API, {
        write: true,
        useraddress: address
      })
    }
    catch(e){
      console.log('DISCLOSURE ERROR: ', e.message)
    }
  }

  const handleConsent = () =>{
    agreement()
    setDisclaimerModal(false)
  }

  return (
    <div className='w-full max-w-[600px] stake-modal mx-auto flex flex-col barlow'>
        <p className='flex items-center justify-center pt-5 text-[33px] font-["Tourney"] font-extrabold gradient-text text-center uppercase mb-4'>
            Disclaimer
        </p>
        <p className='text-sm text-white px-4 sm:px-8'>
            The following disclaimer governs your use of the Pepe's Game website and its associated services (referred to as "the website" or "the service"). By accessing or using the website, you acknowledge and agree to the terms and conditions outlined in this disclaimer.
        </p>
        <div className="self-center w-full sm:w-4/5">
              <Disclosure>
                {({ open }) => (
                  <div
                    className={`flex flex-col gap-[10px] sm:gap-0 `}
                  >
                    <Disclosure.Button
                      className={`flex w-full sm:w-4/5 mx-auto ${
                        !open
                          ? ''
                          : 'hidden'
                      }  px-4 py-2 text-left text-sm font-medium text-white justify-center gap-2`}
                    >
                      <span className={`${open ? 'hidden' : ''} text-center sm:text-left text-[13px] sm:text-base`}>
                         Expand Disclaimer
                      </span>
                      <img src={up} className={`self-center rotate-180 `} />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-500 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="p-4 text-sm text-white rounded-b-lg">
                        <ol className="px-2 ml-2 text-[13px] list-decimal sm:text-base sm:ml-0">
                          <li className="mb-3">
                            <p>
                            <span className="font-bold">No Representation of Benefit: </span>
                            <span>
                                Pepe's Game (referred to as "we" or "us") does not make any representation, promise, guarantee, or assurance that any rewards, benefits, or resources obtained through the website, including but not limited to in-game assets or virtual currencies, will be utilized or spent for your desired purposes or provide any specific outcomes.
                            </span>
                            </p>
                          </li>
                          <li className="mb-3">
                            <p>
                            <span className="font-bold">Availability and Performance: </span>
                            <span>
                                Pepe's Game strives to maintain the availability and performance of the website to the best of its abilities. However, we do not guarantee uninterrupted access to the website or its flawless performance. The website may experience downtime, technical issues, or other limitations beyond our control.
                            </span>
                            </p>
                          </li>
                          <li className="mb-3">
                            <p>
                            <span className="font-bold">User-Generated Content: </span>
                            <span>
                                The website may allow users to generate and share content, including but not limited to artwork, comments, and messages. Pepe's Game does not endorse or take responsibility for any user-generated content. Users are solely responsible for the content they create, share, or engage with on the website.
                            </span>
                            </p>
                          </li>
                          <li className="mb-3">
                            <p>
                            <span className="font-bold">External Links: </span>
                            <span>
                                The website may contain links to external websites or resources. Pepe's Game is not responsible for the content, accuracy, or availability of these external links. The inclusion of any external links on the website does not imply endorsement or affiliation with the linked website.
                            </span>
                            </p>
                          </li>
                          <li className="mb-3">
                            <p>
                            <span className="font-bold">Age Restriction: </span>
                            <span>
                                The website and its services are intended for individuals who are of legal age to form a binding contract in their respective jurisdiction. If you are under the legal age in your jurisdiction, or if you are a citizen of the United States of America (USA) or Canada, you are not permitted to access or use the website or its services. 
                            </span>
                            </p>
                          </li>
                          <li className="mb-3">
                            <p>
                            <span className="font-bold">Financial Considerations: </span>
                            <span>
                                Pepe's Game does not provide financial, investment, or legal advice. Any information provided on the website is for informational purposes only and should not be considered as professional advice. You should conduct your own research and consult with appropriate professionals before making any financial or investment decisions.
                            </span>
                            </p>
                          </li>
                        </ol>

                        <div className="flex justify-center gap-4 my-4 sm:gap-8">
                          <Disclosure.Button className="inline-block mx-auto">
                          <img src={up} className={`self-center`} />
                          </Disclosure.Button>
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            </div>
            <div className='text-sm text-white font-bold text-[13px] px-4 sm:px-8 pb-4'>
                By using the website, you acknowledge and accept that your use of the website and its services is at your own risk. Pepe's Game shall not be liable for any damages or losses arising from your use of the website or reliance on any information or content provided.
            </div>
            <button 
            onClick={handleConsent}
            className='bg-deep-blue/80 px-7 py-2 border-wallet-popup-copy-btn inter text-sm text-white rounded-md mt-4 mb-8 self-center mx-8'>
                I have read the whole disclaimer and I agree
            </button>
    </div>
  )
}

export default Disclaimer