import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { StoreContext } from '../utils/store';
import { ethers, BigNumber } from 'ethers';
import { pepeStakingContractAddress, esPegStakingContractAddress, pepeLockingContractAddress, pepeEsPegLockUpContractAddress, pepeEsPegStakingContractAddress } from '../constants'
import { useAccount, useSigner, erc20ABI, useProvider } from 'wagmi';
import { abi } from '../utils/pepebet-abi';
import { getDollarValue, getSelectedTokenData, getPegPrice } from '../services/service';
import { pepeStaking } from '../utils/pepeStaking';
import { pepeEsPegStakingABI } from '../utils/pepeEsPegStakingABI';
import { pepeLock } from '../utils/pepeLock';
import { pepeEsPegLockABI } from '../utils/pepeEsPegLockABI'
import { dollarFormatter } from '../utils/dollarFormatter';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const Wallet = ({ token, hasClaim, hasLockStake }) => {
  const store = useContext(StoreContext);
  const { setDepositWithdrawModal, setWalletMenuPopup, showWalletMenuPopup, setDepositWithdrawToken, setDepositWithdrawOperation } = store;
  const [formattedUserInAppBalance, setFormattedUserInAppBalance] = useState('0');
  const [formattedUserWalletBalance, setFormattedUserWalletBalance] = useState('0');
  const [formattedUserWalletDollarBalance, setFormattedUserWalletDollarBalance] = useState('0');
  const [peg_staking_dollar, setPEGStakingDollar] = useState(0)
  const [espeg_staking_dollar, setesPEGStakingDollar] = useState(0)
  const [peg_staking, setPEGStaking] = useState(0)
  const [espeg_staking, setesPEGStaking] = useState(0)
  const [peg_locked, setPEGLocked] = useState(0)
  const [espeg_locked, setesPEGLocked] = useState(0)

  const [peg_locked_dollar, setPEGLockedDollar] = useState(0)
  const [espeg_locked_dollar, setesPEGLockedDollar] = useState(0)

  const [claimableUSDC, setClaimableUSDC] = useState(0);
  const [claimablePEG, setClaimablePEG] = useState(0);
  const [claimablePEGDollar, setClaimablePEGDollar] = useState(0);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [refresh, refreshData] = useState(false);

  const { address } = useAccount();
  const { data: signer } = useSigner();
  const provider = useProvider()

  const tokenData = getSelectedTokenData(token)

  const InAppContract = useMemo(() => {
    if (tokenData.trade) {
      return new ethers.Contract(tokenData?.contractAddress, abi, signer);
    }
  }, [signer]);

  const WalletContract = useMemo(() => {
    return new ethers.Contract(tokenData.tokenAddres, erc20ABI, signer);
  }, [signer]);

  const stakingContract = useMemo(
    () => new ethers.Contract(pepeStakingContractAddress, pepeStaking, provider),
    [provider]
  );
  const lockingContract = useMemo(
    () => new ethers.Contract(pepeLockingContractAddress, pepeLock, provider),
    [provider]
  );

  const esPegStakingContract = useMemo(
    () => new ethers.Contract(pepeEsPegStakingContractAddress, pepeEsPegStakingABI, provider),
    [provider]
  );

  const getUserInAppBalance = useCallback(async () => {
    if (tokenData.trade) {
      try {
        const userBalance = await InAppContract.balances(address);
        // setUserInAppUSDCBalance(userBalance);
        const formattedUserBalance = ethers.utils.formatUnits(userBalance, tokenData.formatUnits);
        setFormattedUserInAppBalance(parseFloat(formattedUserBalance).toFixed(4));
      } catch (e) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, InAppContract]);

  const getUserWalletBalance = useCallback(async () => {
    try {
      const balance = await WalletContract.balanceOf(address);
      // setUserWalletBalance(balance);
      setFormattedUserWalletBalance(parseFloat(ethers.utils.formatUnits(balance, tokenData.formatUnits)).toFixed(4));
      let pegPrice = await getPegPrice();
      setFormattedUserWalletDollarBalance(parseFloat(pegPrice * parseFloat(ethers.utils.formatUnits(balance, tokenData.formatUnits)).toFixed(4)).toFixed(2))
    } catch (e) { }
  }, [address, WalletContract]);

  const getPEGStaking = async () => {
    const stakingContractPEG = new ethers.Contract(pepeStakingContractAddress, pepeStaking, provider);
    const peg_staking = await stakingContractPEG.userStake(address);
    //console.log("PEG",peg_staking.amount)
    setPEGStaking(ethers.utils.formatUnits(peg_staking.amount, 18))
    let pegPrice = await getPegPrice();
    setPEGStakingDollar(parseFloat(pegPrice * parseFloat(ethers.utils.formatUnits(peg_staking.amount, 18))).toFixed(2))
  }

  const getesPEGStaking = async () => {
    const stakingContractesPEG = new ethers.Contract(esPegStakingContractAddress, pepeEsPegStakingABI, provider);
    const espeg_staking_count = await stakingContractesPEG.userStakeCount(address);
    let accumulator;
    for (let i = 0; i < espeg_staking_count.toNumber(); i++) {
      const espeg_staking = await stakingContractesPEG.stakes(address, i);
      if (espeg_staking) {
        if (accumulator) {
          accumulator = accumulator.add(espeg_staking.amount)
        } else {
          accumulator = accumulator = espeg_staking.amount
        }

      }

    }


    setesPEGStaking(ethers.utils.formatUnits(accumulator, 18))
    let pegPrice = await getPegPrice();
    setesPEGStakingDollar(parseFloat(pegPrice * parseFloat(ethers.utils.formatUnits(accumulator, 18))).toFixed(2))
  }

  const getPEGLocked = async () => {
    const lockedContractPEG = new ethers.Contract(pepeLockingContractAddress, pepeLock, provider);
    const peg_lock = await lockedContractPEG.lockDetails(address);
    //lets get the dollar value
    if (!peg_lock.wethLocked.isZero() && !peg_lock.pegLocked.isZero()) {
      let pegPrice = await getPegPrice();
      let wethAmount = ethers.utils.formatUnits(peg_lock.wethLocked, 18)
      let pegAmount = ethers.utils.formatUnits(peg_lock.pegLocked, 18)

      //let prices = pegPrice * pegAmount
      //let sum = prices.reduce((a, b) => a + parseFloat(b), 0)
      setPEGLocked(parseFloat(pegAmount).toFixed(4))
      setPEGLockedDollar(parseFloat(pegPrice * pegAmount).toFixed(2))
    }
  }

  const getesPEGLocked = async () => {
    const lockedContractesPEG = new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, provider);
    const espeg_locked_count = await lockedContractesPEG.userLockCount(address);

    let weth_accumulator;
    let esPeg_accumulator;
    for (let i = 0; i < espeg_locked_count.toNumber(); i++) {
      const espeg_locked = await lockedContractesPEG.lockUpDetails(address, i);
      console.log("esPEG LOCKED:", espeg_locked)
      if (espeg_locked) {
        if (weth_accumulator) {
          weth_accumulator = weth_accumulator.add(espeg_locked.wethLocked)
          esPeg_accumulator = esPeg_accumulator.add(espeg_locked.esPegLocked)
        } else {
          weth_accumulator = weth_accumulator = espeg_locked.wethLocked
          esPeg_accumulator = esPeg_accumulator = espeg_locked.esPegLocked
        }

      }

    }
    if (weth_accumulator && esPeg_accumulator) {
      let pegPrice = await getPegPrice();
      let peg_amount = parseFloat(pegPrice * ethers.utils.formatUnits(esPeg_accumulator, 18)).toFixed(4)
      //await getDollarValue("weth", { weth: ethers.utils.formatUnits(weth_accumulator, 18), peg: ethers.utils.formatUnits(esPeg_accumulator, 18) });
      //let sum = prices.reduce((a, b) => a + parseFloat(b), 0)
      setesPEGLocked(peg_amount)
     
      setesPEGLockedDollar(parseFloat(pegPrice * peg_amount).toFixed(2))
    }
  }


  const getUSDCClaimable = async () => {
    //const claimableUSDCRewardsAddresses=[]
    const stakingContract = new ethers.Contract(pepeStakingContractAddress, pepeStaking, provider);
    const lockingContract = new ethers.Contract(pepeLockingContractAddress, pepeLock, provider);
    //get amount user can claim
    const staking_reward = await stakingContract.pendingRewards(address);
    const locking_reward = await lockingContract.pendingUsdcRewards(address);
    setClaimableUSDC(ethers.utils.formatUnits(staking_reward.add(locking_reward), 6))
  }

  const getPEGCliamable = async () => {
    //const claimablePEGRewardsAddresses=[]

    //get claimable amount from esPEGStaking Contract
    const lockedContractesPEG = new ethers.Contract(pepeEsPegLockUpContractAddress, pepeEsPegLockABI, provider);
    const esPEGStakingContract = new ethers.Contract(esPegStakingContractAddress, pepeEsPegStakingABI, provider);
    const esPEG_reward_staking = await esPEGStakingContract.pendingRewards(address);
    //get lockings count
    const espeg_locked_count = await lockedContractesPEG.userLockCount(address);

    let reward_accumulator;
    for (let i = 0; i < espeg_locked_count.toNumber(); i++) {
      const espeg_locked_reward = await lockedContractesPEG.pendingUsdcRewards(address, i);
      console.log("ESPEG LOCKED reward:", espeg_locked_reward)
      if (espeg_locked_reward) {
        if (reward_accumulator) {
          reward_accumulator = reward_accumulator.add(espeg_locked_reward)
        } else {
          reward_accumulator = reward_accumulator = espeg_locked_reward
        }

      }

    }
    if (reward_accumulator || esPEG_reward_staking) {
      let prices = await getDollarValue("weth", { weth: 0, peg: reward_accumulator ? ethers.utils.formatUnits(reward_accumulator.add(esPEG_reward_staking), 18) : ethers.utils.formatUnits(esPEG_reward_staking, 18) });
      //console.log("PRICES:",prices)
      let sum = prices.reduce((a, b) => a + parseFloat(b), 0)
      setClaimablePEG(sum)
      let pegPrice = await getPegPrice();
      setClaimablePEGDollar(parseFloat(pegPrice * sum).toFixed(2))
    }
  }

  const openDepositWithdrawal = (coin, operation) => {
    setDepositWithdrawToken(coin)
    setDepositWithdrawOperation(operation)
    setWalletMenuPopup(false)
    setDepositWithdrawModal(true)
  }

  const navigateToStakeLock = (tab) => {
    navigate(`/${tab}`);
    setWalletMenuPopup(false);
  }

  useEffect(() => {
    if (showWalletMenuPopup) {
      getUserInAppBalance();
      getUserWalletBalance();
      getesPEGStaking();
      getPEGStaking();
      getPEGLocked();
      getesPEGLocked();
      getUSDCClaimable();
      getPEGCliamable();
    }
  }, [address, showWalletMenuPopup, refresh]);

  const pendingRewards = useCallback(async () => {
    try {
      const stakingReward = await stakingContract.pendingRewards(address); // USDC
      const lockingReward = await lockingContract.pendingUsdcRewards(address); // USDC
      const esPegStackingReward = await esPegStakingContract.pendingRewards(address); //peg
      return {
        stakingReward,
        lockingReward,
        esPegStackingReward,
      };
    } catch (error) {
      console.log(error);
    }
  }, [address, lockingContract, stakingContract]);

  const claimStakingReward = async () => {
    enqueueSnackbar('Claiming PEG Staking Rewards...', {
      variant: 'success',
    });
    const { stakingReward } = await pendingRewards();
    const stakingContract_ = new ethers.Contract(pepeStakingContractAddress, pepeStaking, signer);

    if (!stakingReward.isZero()) {
      let estimateGas;
      try {
        estimateGas = await stakingContract_.estimateGas.claimRewards();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await stakingContract_.claimRewards({ gasLimit: estimateGas });
        const receipt = await tx.wait();

        enqueueSnackbar(`Successfully claimed PEG Staking Rewards. Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });

        // return;
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed in claiming PEG Staking Rewards', {
          variant: 'error',
        });
        // return;
      } finally {
        refreshData(!true);
      }
    }
  };

  const claimLockingReward = async () => {
    enqueueSnackbar('Claiming PEG Locking Rewards...', {
      variant: 'success',
    });
    const { lockingReward } = await pendingRewards();
    const pepeLockUp_ = new ethers.Contract(pepeLockingContractAddress, pepeLock, signer);
    if (!lockingReward.isZero()) {
      let estimateGas;
      try {
        estimateGas = await pepeLockUp_.estimateGas.claimUsdcRewards();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await pepeLockUp_.claimUsdcRewards({ gasLimit: estimateGas });
        const receipt = await tx.wait();
        enqueueSnackbar(`Successfully claimed PEG Locking Rewards. Tx hash: ${receipt.transactionHash}`, {
          variant: 'success',
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed in claiming PEG Locking Rewards', {
          variant: 'error',
        });
      }
    }
  };


  const handleAllUsdcClaims = async () => {
    await claimStakingReward();
    await claimLockingReward();
    getUSDCClaimable();
  };

  const handleAllPegClaims = async () => {
    enqueueSnackbar('Claiming esPeg Staking Rewards...', {
      variant: 'success',
    });
    const { esPegStackingReward } = await pendingRewards();
    if (!esPegStackingReward.isZero()) {
      const esPegStaking_ = new ethers.Contract(esPegStakingContractAddress, pepeEsPegStakingABI, signer);
      let estimateGas;
      try {
        estimateGas = await esPegStaking_.estimateGas.claimAll();
        estimateGas = estimateGas.toNumber() + 1e4;
      } catch (error) {
        estimateGas = 3e6;
      }
      try {
        const tx = await esPegStaking_.claimAll({ gasLimit: estimateGas });
        const receipt = await tx.wait();
        getPEGCliamable();
        refreshData(true);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed in claiming PEG Staking Rewards', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <div className="text-white pt-[10px] sm:pt-[19px] pb-[6px] sm:pb-0 mb-[7px] sm:mb-5 border-t-[1px] border-solid border-[#18ADFA] rounded-lg">
      <div className="flex gap-[7px] mx-[20px]">
        <img alt={`${token} coin`} src={tokenData.icon} className="w-4 h-4" />

        <div className="flex-1 relative bottom-1">
          {/* first row */}

          <div className="flex justify-between items-end mb-[7px] min-h-[20px]">
            <div className="font-medium leading-none flex items-end">
              <span className="text-[13px] sm:text-[15px]">{token}</span>
              <span>&nbsp;</span>
              <span className="text-[10px] sm:text-[12px] text-white/40">Wallet</span>
            </div>

            <div className="flex items-end gap-[11px] sm:gap-[13px]">
              <div className="flex gap-[11px] items-end relative">
                <button
                  onClick={() => { token !== "USDC" ? navigateToStakeLock("stake") : openDepositWithdrawal(token, 'deposit') }}
                  className="relative z-[1] shadow-background font-medium text-[10px] leading-[10px] tracking-[-0.02em] text-[#18ADFA]"
                >
                  {token === "USDC" ? "Deposit" : "Stake"}
                </button>
                <button
                  onClick={() => token !== "USDC" ? navigateToStakeLock("lock") : openDepositWithdrawal(token, 'withdraw')}
                  className="relative z-[1] shadow-background font-medium text-[10px] leading-[10px] tracking-[-0.02em] text-[#18ADFA]"
                >
                  {token === "USDC" ? "Withdraw" : "Lock"}

                </button>
              </div>

              <div className="font-medium text-[15px] leading-none align-baseline flex items-end gap-1">
                {!(token === "USDC") &&
                  <span className="text-white/40 leading-[-0.02em] text-center font-normal text-[10px] barlow">${formattedUserWalletDollarBalance}</span>
                }
                {token === "USDC" ? dollarFormatter(formattedUserWalletBalance) : formattedUserWalletBalance}
              </div>
            </div>
          </div>

          {/* second row */}
          {tokenData.trade &&
            <div className="flex items-end justify-between text-white/80 mb-[7px] min-h-[20px]">
              <span className="text-[12px] font-medium leading-none align-baseline">Credits</span>
              <span className="text-[12px] font-medium leading-none align-baseline">
                {token === "USDC" ? dollarFormatter(formattedUserInAppBalance) : formattedUserInAppBalance}
              </span>
            </div>
          }

          {hasLockStake &&
            (<div>
              {/* second row */}
              <div className="flex items-end justify-between text-white/80 mb-[7px] min-h-[20px]">
                <span className="text-[12px] font-medium leading-none align-baseline">Staked</span>
                <div className='flex gap-1'>
                  <span className="text-white/40 leading-[-0.02em] text-center font-normal text-[10px] barlow">${token === 'esPEG' ? espeg_staking_dollar : peg_staking_dollar}</span>
                  <span className="text-[12px] font-medium leading-none align-baseline">
                    {/* //would change to a more generic way to display tokens when more tokens are added */}
                    {token === 'esPEG' ? espeg_staking : peg_staking}
                  </span>
                </div>
              </div>

              {/* third row */}
              <div className="flex items-end justify-between text-white/80 mb-[7px] min-h-[20px]">
                <span className="text-[12px] font-medium leading-none align-baseline">Locked</span>
                <div className='flex gap-1'>
                  <span className="text-white/40 leading-[-0.02em] text-center font-normal text-[10px] barlow">${token === 'esPEG' ? espeg_locked_dollar : peg_locked_dollar}</span>
                  <span className="text-[12px] font-medium leading-none align-baseline">
                    {/* //would change to a more generic way to display tokens when more tokens are added */}
                    {token === 'esPEG' ? espeg_locked : peg_locked}
                  </span>
                </div>
              </div>
            </div>)
          }

          {/* third row */}
          {hasClaim && (<div className="flex items-end justify-between text-white/80 font-medium mb-[7px] min-h-[20px]">
            <span className="text-[12px] leading-none align-baseline">{token === 'PEG' ? 'Claimable Vest' : 'Claimable Rewards'}</span>
            <div className="flex items-end gap-[8px] sm:gap-[23px] relative">
              <button onClick={() => token === 'USDC' ? handleAllUsdcClaims() : handleAllPegClaims()} className="relative z-[1] shadow-background text-[10px] leading-none align-baseline tracking-[-0.02em] text-[#18ADFA]">
                Claim
              </button>
              <div className='flex gap-1'>
                {token === "PEG" &&
                  <span className="text-white/40 leading-[-0.02em] text-center font-normal text-[10px] barlow">${claimablePEGDollar}</span>
                }
                <span className="text-[12px] leading-none align-baseline"> {token === 'USDC' ? dollarFormatter(claimableUSDC) : claimablePEG}</span>
              </div>
            </div>
          </div>)
          }

        </div>
      </div>
    </div>
  );
}

export default Wallet;